import Vue from 'vue';
import Vuex from 'vuex';
import schema from './schema.js';
import term from './term.js';
import user from './user.js';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    schema,
    term,
    user,
  },
});
