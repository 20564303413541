export default {
  methods: {
    confirmLeave() {
      return window.confirm(
        'Do you really want to leave? The unsaved changes will be lost!',
      );
    },
    confirmStayInDirtyForm(isDirty) {
      return isDirty && !this.confirmLeave();
    },
    beforeWindowUnload(e, isDirty) {
      if (this.confirmStayInDirtyForm(isDirty)) {
        e.preventDefault();
        e.returnValue = '';
      }
    },
  },
};
