export default {
  data: function() {
    return {
      authenticated: false,
      user: undefined,
      userGroup: undefined,
    };
  },
  methods: {
    async isAuthenticated() {
      console.log('isAuthenticated');
      if (process.env.VUE_APP_REQUIRES_AUTH === 'false') {
        this.authenticated = true;
        this.user = 'adminUser';
        this.userGroup = 'admin';
        await this.$store.dispatch('setUserAsAdmin', this.user);
      } else {
        const user = await this.$auth.getUser();
        this.authenticated = user !== undefined;
        if (user && (!this.user || user['sub'] !== this.user['sub'])) {
          this.user = user;
          await this.$store.dispatch('getUserGroup', this.user['sub']);
          this.userGroup = this.$store.state.user.userGroup;
        }
      }
    },
    login() {
      this.$auth.signInWithRedirect('/');
    },
    async logout() {
      await this.$auth.signOut();
      await this.isAuthenticated();
    },
  },
  computed: {
    ignoreCache() {
      return this.userGroup === 'admin' || this.userGroup === 'writer';
    },
  },
};
