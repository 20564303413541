<template>
  <app-masthead>
    <mds-loader v-if="isLoading"></mds-loader>
    <div v-else-if="!isTermExist">Term {{ activeTerm }} does not exist.</div>
    <mds-section
      v-else
      :title="this.termObject.attributes.name"
      bold
      border="none"
    >
      <template v-slot:mds-section-title-description>
        <div class="icon-placeholder">
          <AppIconTooltip
            icon="pencil"
            tooltip="edit term"
            @click="startEditing"
          />
          <AppIconTooltip
            v-if="termObject[PPT_LABEL] === 'concept'"
            icon="group-row"
            tooltip="go to Hierarchy"
            @click="openHierarchy"
          />
          <AppIconTooltipTextAndHover
            v-if="termObject[PPT_ATTRIBUTES][PPT_USE_CASE_TEXT]"
            icon="document"
            :tooltip="termObject[PPT_ATTRIBUTES][PPT_USE_CASE_TEXT]"
            :variation="'flat'"
            :text="PPT_USE_CASE_LABEL"
            :flatButtonSize="'medium'"
          />
          <AppIconTooltipAndText
            v-if="termObject[PPT_ATTRIBUTES][PPT_DEFINITION_TEXT]"
            icon="paperclip-angle"
            :tooltip="termObject[PPT_ATTRIBUTES][PPT_DEFINITION_TEXT]"
            :variation="'flat'"
            :text="PPT_DEFINITION_LABEL"
            :flatButtonSize="'medium'"
          />
        </div>
      </template>
      <div
        v-if="
          termObject.hasOwnProperty(PPT_ATTRIBUTES) &&
            termObject[PPT_ATTRIBUTES].hasOwnProperty(PPT_IDENTIFIER)
        "
      >
        <span class="bold">Identifer:</span>
        {{ termObject[PPT_ATTRIBUTES][PPT_IDENTIFIER] }}
      </div>
      <div>
        <span class="bold">Class:</span>
        {{ decamelize(termObject[PPT_LABEL]) }}
      </div>
      <div><span class="bold">Database ID:</span> {{ termObject[PPT_ID] }}</div>

      <!-- attributes -->
      <div v-for="(attribute, aIndex) in attributeSchema" :key="aIndex">
        <div v-if="![PPT_NAME, PPT_IDENTIFIER].includes(attribute[PPT_NAME])">
          <span class="bold">{{ attribute['description'] }}:</span>
          <TermViewAttribute
            v-if="
              termObject.hasOwnProperty(PPT_ATTRIBUTES) &&
                termObject[PPT_ATTRIBUTES].hasOwnProperty(attribute[PPT_NAME])
            "
            :value="termObject[PPT_ATTRIBUTES][attribute[PPT_NAME]]"
            :type="attribute['type']"
            :singleValue="attribute['singleValue']"
            :name="attribute[PPT_NAME]"
          />

          <span v-else> None </span>
        </div>
      </div>
      <!-- relationships -->
      <div
        v-for="(relationship, index) in relationshipSchema"
        :key="`${relationship[PPT_NAME]}${index}`"
      >
        <div class="bold">{{ relationship[PPT_DESCRIPTION] }}:</div>
        <div v-if="relationship[PPT_NAME] === PPT_DISCUSSED_IN">
          <mds-select
            label="Locale"
            :options="locales"
            v-model="selectedLocale"
          ></mds-select>
        </div>
        <div class="mds-doc-typography">
          <ul
            style="list-style: none"
            v-for="label in relationship[PPT_TARGET_CLASSES]"
            :key="`${relationship[PPT_NAME]}${label}`"
          >
            <li
              :class="{
                'hide-marker': relationship[PPT_TARGET_CLASSES].length <= 1,
              }"
            >
              <div v-if="relationship[PPT_TARGET_CLASSES].length > 1">
                {{ decamelize(label) }}
              </div>
              <div
                v-if="
                  termObject.hasOwnProperty(PPT_RELATIONSHIPS) &&
                    termObject[PPT_RELATIONSHIPS].hasOwnProperty(
                      relationship[PPT_NAME],
                    ) &&
                    termObject[PPT_RELATIONSHIPS][
                      relationship[PPT_NAME]
                    ].hasOwnProperty(label)
                "
              >
                <ul
                  style="list-style: none"
                  v-for="(relationshipItem, relationshipIndex) in termObject[
                    PPT_RELATIONSHIPS
                  ][relationship[PPT_NAME]][label]"
                  :key="relationshipIndex"
                >
                  <li>
                    <TermViewRelationship :termObject="relationshipItem" />
                  </li>
                </ul>
                <mds-pagination
                  show-items-info
                  v-if="
                    termObject[PPT_RELATIONSHIPS_NUMBER][
                      relationship[PPT_NAME]
                    ][label] > pageSize
                  "
                  :total-items="
                    termObject[PPT_RELATIONSHIPS_NUMBER][
                      relationship[PPT_NAME]
                    ][label]
                  "
                  :pageSize="pageSize"
                  @mds-pagination-page-changed="
                    pageChanged(relationship[PPT_NAME], label, $event)
                  "
                ></mds-pagination>
              </div>
              <div v-else>
                None
                <span
                  v-if="
                    relationship[PPT_NAME] === 'narrowerTerms' &&
                      termObject[PPT_ATTRIBUTES][PPT_CLASSIFIED]
                  "
                >
                  (End Node)
                </span>
                <span
                  v-if="
                    relationship[PPT_NAME] === 'broaderTerms' &&
                      termObject[PPT_ATTRIBUTES][PPT_CLASSIFIED]
                  "
                >
                  (Top Node)
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </mds-section>
  </app-masthead>
</template>

<script>
import AppIconTooltip from '@/components/elements/AppIconTooltip.vue';
import AppIconTooltipAndText from '@/components/elements/AppIconTooltipAndText.vue';
import AppIconTooltipTextAndHover from '@/components/elements/AppIconTooltipTextAndHover.vue';
import AppMasthead from '@/components/elements/AppMasthead.vue';
import Auth from '@/js/auth.js';
import Constants from '@/js/constants.js';
import MdsLoader from '@mds/loader';
import MdsPagination from '@mds/pagination';
import MdsSelect from '@mds/select';
import MdsSection from '@mds/section';
import Request from '@/js/request.js';
import TermViewAttribute from './TermViewAttribute.vue';
import TermViewRelationship from './TermViewRelationship.vue';
import Utils from '@/js/utils.js';
import _ from 'underscore';

export default {
  name: 'TermView',
  components: {
    TermViewAttribute,
    AppIconTooltip,
    AppIconTooltipAndText,
    AppIconTooltipTextAndHover,
    AppMasthead,
    TermViewRelationship,
    MdsLoader,
    MdsPagination,
    MdsSection,
    MdsSelect,
  },
  mixins: [Auth, Constants, Utils],
  data() {
    return {
      termObject: null,
      attributeSchema: null,
      relationshipSchema: null,
      isLoading: false,
      pageSize: 20,
      selectedLocale: null,
      locales: [],
    };
  },
  mounted: async function() {
    this.isLoading = true;
    await this.loadLocales();
    await this.isAuthenticated();
    await this.loadTerm(this.selectedLocale);
  },
  methods: {
    async loadTerm(selectedLocale) {
      this.isLoading = true;
      await this.$store.dispatch('getTermById', {
        termId: this.activeTerm,
        refresh: this.ignoreCache,
        locale: selectedLocale,
      });
      this.termObject = this.$store.state.term.termObject;

      if (!this.termObject) {
        this.isLoading = false;
        return;
      }
      for (let key of Object.keys(this.termObject[this.PPT_RELATIONSHIPS])) {
        if (
          key === 'narrowerTerms' ||
          key === 'broaderTerms' ||
          key === 'relatedTerm'
        ) {
          _.sortBy(
            this.termObject[this.PPT_RELATIONSHIPS][key][this.REL_CONCEPT],
            this.PPT_NAME,
          );
        }
      }
      document.title = `${
        this.termObject[this.PPT_ATTRIBUTES][this.PPT_NAME]
      } | ${process.env.VUE_APP_TITLE}`;
      this.attributeSchema = this.$store.state.term.termSchema[
        this.PPT_ATTRIBUTES
      ];

      this.attributeSchema = this.attributeSchema.filter(
        item =>
          item.name !== this.PPT_USE_CASE_TEXT &&
          item.name !== this.PPT_DEFINITION_TEXT,
      );

      let relationships = this.$store.state.term.termSchema[
        this.PPT_RELATIONSHIPS
      ];

      if (this.termObject.label === this.REL_COUNTRY) {
        relationships = _.filter(
          relationships,
          r =>
            !this.REL_COUNTRY_SKIPPED_RELATIONS.includes(
              r[this.PPT_TARGET_CLASS],
            ),
        );
      }

      const relationshipSchema = [];
      relationships.forEach(element => {
        const index = relationshipSchema.findIndex(
          r => r[this.PPT_NAME] === element[this.PPT_NAME],
        );
        if (index === -1) {
          relationshipSchema.push({
            targetClasses: [element[this.PPT_TARGET_CLASS]],
            name: element[this.PPT_NAME],
            description: element[this.PPT_DESCRIPTION],
          });
        } else {
          relationshipSchema[index][this.PPT_TARGET_CLASSES].push(
            element[this.PPT_TARGET_CLASS],
          );
        }
      });

      this.relationshipSchema = relationshipSchema;
      this.isLoading = false;
    },
    async pageChanged(relationshipName, label, payload) {
      const request = {
        id: this.termObject[this.PPT_ID],
        edge: relationshipName,
        vertex: label,
        start: payload['firstItem'],
        end: payload['lastItem'],
        locale: this.selectedLocale,
        refresh: this.ignoreCache,
      };
      await this.$store.dispatch('getRelationshipPagination', request);
    },

    async loadLocales() {
      let response = await Request.get('/schema/article', this.ignoreCache);
      response['attributes'].forEach(element => {
        if (element.name == 'locale') {
          let locals = element['predefinedList'].split(',');
          for (let local in locals) {
            this.locales.push({
              value: locals[local],
              text: locals[local],
            });
          }
        }
      });
      this.locales = _.sortBy(this.locales, 'value');
    },
    openInCosmos(term) {
      let cosmosURL =
        process.env.VUE_APP_COSMOS_URL +
        term[this.PPT_ATTRIBUTES][this.PPT_DOC_ID];
      window.open(cosmosURL, '_blank');
    },
    startEditing() {
      if (
        this.termObject[this.PPT_ATTRIBUTES][this.PPT_DATA_SOURCE] === 'Cosmos'
      ) {
        this.openInCosmos(this.termObject);
      } else {
        this.routeToPage('TermEdit', {}, this.$route.params);
      }
    },
    openHierarchy() {
      this.routeToPage(
        'ConceptHierarchy',
        {},
        { conceptId: this.$route.params['entryId'] },
        true,
      );
    },
  },
  computed: {
    isTermExist() {
      return this.termObject !== null;
    },
    activeTerm() {
      return this.$route.params.entryId;
    },
    getSelectedLocale() {
      return this.selectedLocale;
    },
  },
  watch: {
    async activeTerm() {
      await this.loadTerm(this.selectedLocale);
    },
    async selectedLocale() {
      await this.loadTerm(this.selectedLocale);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/global.scss';
.icon-placeholder {
  margin-left: #{$mds-space-1-x};
  span {
    padding-right: #{$mds-space-1-x};
  }
  span:last-child,
  span:nth-last-child(2) {
    padding-right: 0px;
  }
}
.mds-doc-typography ul {
  line-height: 26px;
  list-style: none;
  margin: 0;
  padding-left: 28px;
  position: relative;
  margin-bottom: 4px;
  max-width: 800px;
}
.mds-doc-typography ul ul {
  list-style-type: none;
  margin: 0;
  padding-left: 28px;
  position: relative;
}
.mds-doc-typography ul li::before {
  color: #ababab;
  content: '\2022';
  font-size: 16px;
  left: 0;
  line-height: 26px;
  position: absolute;
  vertical-align: baseline;
}
.hide-marker::before {
  display: none;
}
.mds-doc-typography ul li ul li::before {
  content: '\25CB';
  font-size: 16px;
  left: 0;
  line-height: 26px;
  position: absolute;
  vertical-align: baseline;
}
</style>
