<template>
  <div><div class="graphs" ref="chart"></div></div>
</template>

<script>
import * as d3 from 'd3';
import Utils from '@/js/utils.js';
import constants from '@mds/constants/dist/constants.json';

export default {
  name: 'SchemaGraph',
  props: {
    classMap: {
      type: Object,
    },
  },
  mixins: [Utils],
  data() {
    return {
      width: 500,
      height: 500,
      nodes: [],
      links: [],
      mdsConstants: constants,
    };
  },
  methods: {
    draw() {
      if (Object.keys(this.classMap).length == 0) {
        return;
      }
      for (let key in this.classMap) {
        this.nodes.push({
          id: this.decamelize(key),
          group: this.classMap[key].dataSource,
        });
        for (let i in this.classMap[key].relationships) {
          let currentRelationship = this.classMap[key].relationships[i];
          if (
            this.links.some(
              e =>
                e.source === this.decamelize(key) &&
                e.target === this.decamelize(currentRelationship.targetClass),
            )
          ) {
            continue;
          } else {
            this.links.push({
              source: this.decamelize(key),
              target: this.decamelize(currentRelationship.targetClass),
              edgeName: currentRelationship.description,
            });
          }
        }
      }
      let colors = d3
        .scaleOrdinal()
        .domain(this.nodes.map(d => d.group))
        .range(this.graphColors);
      let links = this.links.map(d => Object.create(d));
      let nodes = this.nodes.map(d => Object.create(d));
      let simulation = d3
        .forceSimulation(nodes)
        .force(
          'link',
          d3.forceLink(links).id(d => d.id),
        )
        .force('charge', d3.forceManyBody().strength(-200))
        .force('collide', d3.forceCollide().radius(12))
        .force('center', d3.forceCenter(this.width / 2, this.height / 2));
      let svg = d3
        .select(this.$refs['chart'])
        .append('svg')
        .attr('preserveAspectRatio', 'xMinYMin meet')
        .attr('viewBox', `0 0 ${this.width} ${this.height}`)
        .classed('svg-content', true);
      const link = svg
        .selectAll('.links')
        .data(links)
        .enter()
        .append('line')
        .attr('class', 'links')
        .attr('stroke', '#ddd')
        .attr('stroke-opacity', 0.6);
      link.append('title').text(d => d.edgeName);
      const edgepaths = svg
        .selectAll('.edgepath')
        .data(links)
        .enter()
        .append('path')
        .attr('class', 'edgepath')
        .attr('fill-opacity', 0)
        .attr('stroke-opacity', 0)
        .attr('id', function(d, i) {
          return 'edgepath' + i;
        })
        .style('pointer-events', 'none');
      const edgelabels = svg
        .selectAll('.edgelabel')
        .data(links)
        .enter()
        .append('text')
        .style('pointer-events', 'none')
        .attr('class', 'edgelabel')
        .attr('id', function(d, i) {
          return 'edgelabel' + i;
        });
      const node_drag = d3.drag();
      node_drag.on('start', event => {
        if (!event.active) simulation.alphaTarget(0.3).restart();
        event.subject.fx = event.subject.x;
        event.subject.fy = event.subject.y;
      });
      node_drag.on('drag', event => {
        event.subject.fx = event.x;
        event.subject.fy = event.y;
      });
      node_drag.on('end', event => {
        if (!event.active) simulation.alphaTarget(0);
        event.subject.fx = null;
        event.subject.fy = null;
      });
      const linkedByIndex = {};
      links.forEach(d => {
        linkedByIndex[d.source.index + ',' + d.target.index] = 1;
      });
      const node = svg
        .append('g')
        .selectAll('.node')
        .data(nodes)
        .join('g')
        .attr('class', 'node')
        .call(node_drag);
      node
        .append('circle')
        .attr('r', 5)
        .attr('fill', d => colors(d.group))
        .on('mouseover', (event, d) => {
          node.style('stroke-opacity', function(o) {
            let thisOpacity =
              linkedByIndex[d.index + ',' + o.index] ||
              linkedByIndex[o.index + ',' + d.index] ||
              d.index == o.index
                ? 1
                : 0.2;
            return thisOpacity;
          });
          node.style('fill-opacity', function(o) {
            let thisOpacity =
              linkedByIndex[d.index + ',' + o.index] ||
              linkedByIndex[o.index + ',' + d.index] ||
              d.index == o.index
                ? 1
                : 0.2;
            return thisOpacity;
          });
          link.style('stroke-opacity', function(o) {
            return o.source === d || o.target === d ? 0.4 : 0.2;
          });
          link.style('stroke', function(o) {
            return o.source === d || o.target === d ? colors(d.group) : '#ddd';
          });
          edgelabels
            .attr('font-size', 9)
            .attr('fill', function(o) {
              if (o.source === d) {
                return '#000';
              }
            })
            .attr('opacity', function(o) {
              if (o.source !== d) {
                return 0;
              }
            })
            .append('textPath')
            .attr('xlink:href', function(d, i) {
              return '#edgepath' + i;
            })
            .style('text-anchor', 'middle')
            .attr('startOffset', '50%')
            .text(function(o) {
              if (o.source === d) {
                return o.edgeName;
              }
            });
        })
        .on('mouseout', () => {
          node.style('stroke-opacity', 1);
          node.style('fill-opacity', 1);
          link.style('stroke-opacity', 1);
          link.style('stroke', '#ddd');
          edgelabels.attr('opacity', 0);
        });
      node
        .append('text')
        .text(function(d) {
          return d.id;
        })
        .style('fill', '#000')
        .style('font-size', '10px')
        .attr('x', 6)
        .attr('y', 3);
      simulation.on('tick', () => {
        link
          .attr('x1', d => d.source.x)
          .attr('y1', d => d.source.y)
          .attr('x2', d => d.target.x)
          .attr('y2', d => d.target.y);
        node.attr('transform', d => `translate(${d.x}, ${d.y})`);
        edgepaths.attr(
          'd',
          d =>
            'M ' +
            d.target.x +
            ' ' +
            d.target.y +
            ' L ' +
            d.source.x +
            ' ' +
            d.source.y,
        );
      });
    },
  },
  async mounted() {
    this.drawNetwork = this.draw;
    this.drawNetwork();
  },
  computed: {
    graphColors() {
      let result = [];
      const chartColors = this.mdsConstants.mds_constants['chart-color'];
      for (let key in chartColors) {
        result.push(chartColors[key]);
      }
      return result;
    },
  },
  watch: {
    classMap() {
      this.drawNetwork();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/global.scss';
@import '@mds/constants';
@import '@mds/typography';

.graphs {
  min-width: 600px;
  max-width: 750px;
  margin: #{-$mds-space-3-x} 0 0 0;
}
</style>
