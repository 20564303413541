import App from './App.vue';
import Vue from 'vue';
import axios from 'axios';
import router from './js/router.js';
import store from './store';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';

Vue.config.productionTip = false;

new Vue({
  router,
  store: store,
  render: h => h(App),
}).$mount('#app');
