<template>
  <mds-notification-container>
    <mds-notification
      v-for="item in list"
      :key="item.id"
      size="small"
      :title="item.title"
      :variation="item.variation"
      :dismissDelay="item.dismissDelay"
      @mds-notification-dismissed="removeItem(item.id)"
    >
      <span v-html="item.message"></span>
      <template v-slot:mds-notification-custom-action v-if="item.action">
        <mds-button-container>
          <mds-button
            class="dangerous-button"
            type="button"
            variation="primary"
            size="small"
            :text="item.actionText"
            :disabled="isTakingAction"
            @click="takeAction(item)"
          ></mds-button>
          <mds-button
            type="button"
            variation="secondary"
            text="Cancel"
            size="small"
            :disabled="isTakingAction"
            @click="removeItem(item.id)"
          ></mds-button>
        </mds-button-container>
      </template>
    </mds-notification>
  </mds-notification-container>
</template>

<script>
import { MdsButton, MdsButtonContainer } from '@mds/button';
import { MdsNotification, MdsNotificationContainer } from '@mds/notification';
import EventBus from '@/js/event-bus.js';

export default {
  name: 'AppNotifications',
  components: {
    MdsButton,
    MdsButtonContainer,
    MdsNotification,
    MdsNotificationContainer,
  },
  data() {
    return {
      list: [],
      currentId: 0,
      isTakingAction: false,
    };
  },
  mounted() {
    EventBus.$on('addNotification', this.addItem);
  },
  methods: {
    async takeAction(item) {
      this.isTakingAction = true;
      await item.action();
      this.isTakingAction = false;
      this.removeItem(item.id);
    },
    removeItem(id) {
      this.list = this.list.filter(item => item.id !== id);
    },
    addItem(event) {
      const variation =
        ['error', 'informational', 'success', 'warning'].indexOf(
          event.variation,
        ) !== -1
          ? event.variation
          : 'informational';
      const dismissDelay =
        event.dismissDelay ??
        (event.action ? 99999 : variation === 'success' ? 1000 : 5000);
      const item = {
        id: this.currentId,
        variation: variation,
        dismissDelay: dismissDelay,
        title: event.title,
        message: event.message,
        active: true,
        action: event.action,
        actionText: event.actionText,
      };
      this.currentId++;
      this.list.unshift(item);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/global.scss';
</style>
