<template>
  <nav>
    <mds-section title="Working Dashboard">
      <mds-list-group variation="navigation">
        <mds-list-group-item
          text="Mapping Status"
          @mds-list-item-clicked="routeToPage('WorkingDashboardStatus')"
        >
        </mds-list-group-item>
        <mds-list-group-item
          v-for="item in pages"
          :key="item"
          :text="item"
          @mds-list-item-clicked="
            routeToPage(
              'WorkingDashboard',
              {},
              { className: item.split(' ')[1].toLowerCase() },
            )
          "
        >
        </mds-list-group-item>
        <mds-list-group-item
          text="Batch Mapping"
          @mds-list-item-clicked="routeToPage('BatchMapping')"
        >
        </mds-list-group-item>
      </mds-list-group>
    </mds-section>
  </nav>
</template>

<script>
import { MdsListGroup, MdsListGroupItem } from '@mds/list-group';
import MdsSection from '@mds/section';
import Utils from '@/js/utils.js';

export default {
  name: 'WorkingDashboardNav',
  components: {
    MdsListGroup,
    MdsListGroupItem,
    MdsSection,
  },
  mixins: [Utils],
  data() {
    return {
      pages: ['Unmapped Articles', 'Unmapped Videos'],
    };
  },
};
</script>
