<template>
  <nav>
    <div class="mds-site-navigation__actions">
      <mds-button variation="icon-only" icon="remove" @click="hideHiddenNav">
      </mds-button>
    </div>
    <mds-section title="Management">
      <mds-list-group variation="navigation">
        <mds-list-group-item
          v-for="item in navList"
          :key="item.text"
          :text="item.text"
          @mds-list-item-clicked="routeTo(item.route)"
        >
        </mds-list-group-item>
      </mds-list-group>
    </mds-section>
  </nav>
</template>

<script>
import { MdsButton } from '@mds/button';
import { MdsListGroup, MdsListGroupItem } from '@mds/list-group';
import MdsSection from '@mds/section';
import Utils from '@/js/utils.js';

export default {
  name: 'AppHiddenMenu',
  components: { MdsButton, MdsListGroup, MdsListGroupItem, MdsSection },
  mixins: [Utils],
  data() {
    return {
      navList: [
        { text: 'Concept Hierarchy', route: 'ConceptHierarchy' },
        { text: 'Schema Management', route: 'SchemaManagement' },
        { text: 'Working Dashboard', route: 'WorkingDashboardStatus' },
        { text: 'Custom Query', route: 'CustomQuery' },
      ],
    };
  },
  methods: {
    hideHiddenNav() {
      this.$parent.hideHiddenNav();
    },
    routeTo(routeName) {
      this.hideHiddenNav();
      this.routeToPage(routeName);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/global.scss';
.mds-site-navigation__actions {
  display: flex;
  justify-content: flex-end;
  padding: 0 0 $mds-space-1-x;
}
</style>
