<template>
  <app-masthead>
    <mds-loader v-if="isLoading || isLoadingAuth"></mds-loader>
    <div v-else-if="!hasAccess">Sorry, you don't have write access.</div>
    <div v-else>
      <mds-form onsubmit="return false">
        <mds-select
          name="taxonomyClass"
          v-model="termObject['label']"
          label="Primary Classification (Class)"
          :options="classOptions"
          @change="classSelected"
        ></mds-select>
        <TermEditAttribute
          v-for="(attribute, attributeIdx) in attributeSchema"
          v-bind="attribute"
          :key="`${attribute.name}${attributeIdx}`"
          @change="attributeChanged(attribute.name, $event)"
          ref="attributes"
        />
        <TermEditRelationship
          v-for="relationship in relationshipSchema"
          :key="`${relationship.name}${relationship.targetClass}`"
          v-bind="relationship"
          @change="relationshipChanged(relationship, $event)"
          ref="relationships"
        />
        <!-- Action Buttons -->
        <mds-button-container>
          <mds-button
            variation="primary"
            type="button"
            :disabled="!isDirty || isSubmiting"
            :loading="isSubmiting"
            @click="submit"
          >
            Submit
          </mds-button>
          <mds-button :disabled="isSubmiting" @click="cancelEditing">
            Cancel
          </mds-button>
        </mds-button-container>
      </mds-form>
    </div>
  </app-masthead>
</template>

<script>
import Auth from '@/js/auth.js';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import AppMasthead from '@/components/elements/AppMasthead.vue';
import Constants from '@/js/constants.js';
import MdsForm from '@mds/form';
import MdsLoader from '@mds/loader';
import MdsSelect from '@mds/select';
import TermEditAttribute from './TermEditAttribute.vue';
import TermEditRelationship from './TermEditRelationship.vue';
import UnsavedChanges from '@/js/unsaved-changes.js';
import Utils from '@/js/utils.js';
import moment from 'moment';

export default {
  name: 'TermCreate',
  components: {
    AppMasthead,
    MdsButton,
    MdsButtonContainer,
    MdsForm,
    MdsLoader,
    MdsSelect,
    TermEditAttribute,
    TermEditRelationship,
  },
  mixins: [Auth, Constants, UnsavedChanges, Utils],
  mounted: async function() {
    //TODO: called isAuthenticate twice
    this.isLoadingAuth = true;
    await this.isAuthenticated();
    this.isLoadingAuth = false;
    if (this.hasAccess) {
      await this.load();
      window.addEventListener('beforeunload', this.onLeavePage);
    }
  },
  destroyed() {
    window.removeEventListener('beforeunload', this.onLeavePage);
  },
  data() {
    return {
      termObject: this.initClass(''),
      isLoading: false,
      isLoadingAuth: false,
      isDirtyAttribute: false,
      isDirtyRelationship: false,
      isSubmiting: false,
      classOptions: [],
      termSchema: [],
      attributeSchema: [],
      relationshipSchema: [],
      toggleDelete: false,
    };
  },
  methods: {
    async load() {
      this.isLoading = true;
      await this.loadClassOptions();
      this.isLoading = false;
    },
    async loadTerm() {
      this.termObject = {};
      this.attributeSchema = [];
      this.relationshipSchema = [];
      return;
    },
    loadSchema(className = null) {
      this.termSchema = this.$store.state.schema.classMap[className];
      let attributeSchema = this.termSchema[this.PPT_ATTRIBUTES];
      let relationshipSchema = this.termSchema[this.PPT_RELATIONSHIPS];
      // assign values to attributes and relationships
      attributeSchema.forEach(attribute => {
        if (attribute['name'] === 'dataSource') {
          attribute['value'] = 'Taxonomy';
        }
        if (attribute['name'] === 'updateDate') {
          let today = moment(new Date()).format('YYYY-MM-DD');
          attribute['value'] = today;
        }
      });
      this.attributeSchema = [];
      this.relationshipSchema = [];
      setTimeout(() => {
        this.attributeSchema = attributeSchema;
        this.relationshipSchema = relationshipSchema;
      }, 1);
    },
    async loadClassOptions() {
      await this.$store.dispatch('getAllClasses');
      let classOptions = [];
      for (let key in this.$store.state.schema.classMap) {
        if (
          this.$store.state.schema.classMap[key][this.PPT_DATA_SOURCE].includes(
            'Taxonomy',
          )
        ) {
          classOptions.push({
            value: key,
            text: this.decamelize(key),
          });
        }
      }
      this.classOptions = classOptions;
    },
    classSelected(className) {
      this.isDirtyAttribute = true;
      this.loadSchema(className);
      this.termObject = this.initClass(className);
    },
    initClass(className) {
      return {
        label: className,
        attributes: { dataSource: 'Taxonomy' },
        relationships: {},
        relationships_add: {},
        relationships_delete: {},
      };
    },
    cancelEditing() {
      if (!this.confirmStayInDirtyForm(this.isDirty)) {
        this.routeToPage('Home');
      }
    },
    attributeChanged(attributeName, newValue) {
      if (!this.termObject.hasOwnProperty(this.PPT_ATTRIBUTES))
        this.termObject[this.PPT_ATTRIBUTES] = {};
      this.termObject[this.PPT_ATTRIBUTES][attributeName] = newValue;
      this.isDirtyAttribute = true;
    },
    relationshipChanged(relationship, newValue) {
      const name = relationship.name;
      const targetClass = relationship.targetClass;
      if (Object.keys(newValue['relationships_add']).length > 0) {
        if (!this.termObject.hasOwnProperty('relationships_add'))
          this.termObject['relationships_add'] = {};
        if (!this.termObject['relationships_add'].hasOwnProperty(name))
          this.termObject['relationships_add'][name] = {};
        this.termObject['relationships_add'][name][targetClass] =
          newValue['relationships_add'];
      }
      if (Object.keys(newValue['relationships_delete']).length > 0) {
        if (!this.termObject.hasOwnProperty('relationships_delete'))
          this.termObject['relationships_delete'] = {};
        if (!this.termObject['relationships_delete'].hasOwnProperty(name))
          this.termObject['relationships_delete'][name] = {};
        this.termObject['relationships_delete'][name][targetClass] =
          newValue['relationships_delete'];
      }
      this.isDirtyRelationship = true;
    },
    async submit() {
      this.isSubmiting = true;
      const validated = await this.validate();
      if (!validated) {
        this.isSubmiting = false;
        return;
      }

      const actionName = 'addEntry';
      let termObject = this.termObject;
      this.$store.dispatch('setSchema', this.termSchema);
      await this.$store.dispatch(actionName, termObject);

      this.isSubmiting = false;
      if (this.$store.state.term.termObject) {
        this.routeToPage(
          'TermView',
          {},
          { entryId: this.$store.state.term.termObject['id'] },
        );
      }
    },
    async validate() {
      let result = '';
      for (let index = 0; index < this.attributeSchema.length; index++) {
        let validateString = await this.$refs.attributes[index].validate();
        result = result + validateString;
      }
      for (let index = 0; index < this.relationshipSchema.length; index++) {
        let validateString = await this.$refs.relationships[index].validate();
        result = result + validateString;
      }
      if (result !== '') {
        this.notifyError(
          `Please review ${result}then try again.`,
          'Validation failed',
        );
        return false;
      }
      return true;
    },
    onLeavePage(e) {
      this.beforeWindowUnload(e, this.isDirty);
    },
  },
  computed: {
    hasAccess() {
      return this.userGroup === 'admin' || this.userGroup === 'writer';
    },
    isDirty() {
      return this.isDirtyAttribute || this.isDirtyRelationship;
    },
    isTermExist() {
      return this.termObject !== null;
    },
    activeTerm() {
      return this.$route.params.entryId;
    },
    hasRelationship() {
      return (
        this.termObject.hasOwnProperty('relationships') &&
        Object.keys(this.termObject['relationships']).length > 0
      );
    },
  },
  watch: {
    async activeTerm() {
      await this.load();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/global.scss';
.scr-trigger-margin {
  margin-bottom: #{$mds-space-2-x};
}
</style>
