<template>
  <g>
    <path
      :d="getPath(link)"
      :stroke="linkStroke"
      fill="none"
      :stroke-width="linkStrokeWidth"
    ></path>
  </g>
</template>

<script>
import * as d3 from 'd3';

export default {
  name: 'ConceptHierarchyLink',
  props: {
    link: {
      type: Object,
    },
    nodeWidth: {
      type: Number,
    },
    nodeHeight: {
      type: Number,
    },
    linkStroke: {
      type: String,
    },
    linkStrokeWidth: {
      type: Number,
    },
  },
  data() {
    return {
      createLink: d3
        .linkHorizontal()
        .x(d => d.x)
        .y(d => d.y),
    };
  },
  methods: {
    getCenter(node) {
      return {
        x: node.x + this.nodeWidth / 2,
        y: node.y + this.nodeHeight / 2,
      };
    },
    getPath(link) {
      let source = this.getCenter(link.source);
      let target = this.getCenter(link.target);
      return this.createLink({
        source,
        target,
      });
    },
  },
};
</script>
