<template>
  <div>
    <h3 class="title">{{ title }}</h3>
    <div class="graphs" ref="chart"></div>
  </div>
</template>

<script>
import * as d3 from 'd3';

export default {
  name: 'WorkingDashBoardDonut',
  props: {
    title: {
      type: String,
    },
    data: {
      type: [String, Array],
    },
  },
  data() {
    return {
      width: 500,
      height: 500,
    };
  },
  methods: {
    draw() {
      if (this.data.length != 0) {
        const radius = Math.min(this.width, this.height) / 2;
        const svg = d3
          .select(this.$refs['chart'])
          .append('svg')
          .attr('width', '100%')
          .attr('height', '100%')
          .attr('viewBox', `0 0 ${this.width} ${this.height}`)
          .append('g')
          .attr(
            'transform',
            'translate(' + this.width / 2 + ',' + this.height / 2 + ')',
          );
        let color = d3
          .scaleOrdinal()
          .domain(this.data.map(d => d.name))
          .range(['#1f55a5', '#a50032']);
        let pie = d3
          .pie()
          .padAngle(0.01)
          .sort(null)
          .value(d => d.value);
        let arc = d3
          .arc()
          .innerRadius(radius * 0.55)
          .outerRadius(radius - 1);
        const arcsData = pie(this.data);
        svg
          .selectAll('path')
          .data(arcsData)
          .join('path')
          .attr('fill', d => color(d.data.name))
          .attr('d', arc)
          .append('title')
          .text(d => `${d.data.name}: ${d.data.value.toLocaleString()}`);
        svg
          .append('g')
          .attr('font-size', 21)
          .attr('text-anchor', 'middle')
          .selectAll('text')
          .data(arcsData)
          .join('text')
          .attr('transform', d => `translate(${arc.centroid(d)})`)
          .style('fill', 'white')
          .call(text =>
            text
              .append('tspan')
              .attr('y', '-0.4em')
              .attr('font-weight', 'bold')
              .text(d => d.data.name),
          )
          .call(text =>
            text
              .filter(d => d.endAngle - d.startAngle > 0.25)
              .append('tspan')
              .attr('x', 0)
              .attr('y', '0.7em')
              .attr('font-weight', 'bold')
              .text(
                d => `${d.data.value.toLocaleString()} (${d.data.percentage})`,
              ),
          );
      }
    },
  },
  watch: {
    data() {
      this.drawDonut();
    },
  },
  mounted() {
    this.drawDonut = this.draw;
    this.drawDonut();
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/global.scss';
@import '@mds/constants';
@import '@mds/typography';

.title {
  @include mds-level-5-heading($bold: true);
}
.graphs {
  max-width: 320px;
  margin: auto;
}
</style>
