<template>
  <div>
    <mds-label :required="required" :text="label">
      <mds-radio-button
        :checked="isTrue"
        @input="trueSelected"
        :disabled="disabled"
      >
        True
      </mds-radio-button>
      <mds-radio-button
        :checked="isFalse"
        @input="falseSelected"
        :disabled="disabled"
      >
        False
      </mds-radio-button>
      <div v-if="error" class="mds-input__field-error-wrapper-dp">
        <span
          class="mds-field-error"
          v-for="(text, index) in errorText"
          :key="index"
        >
          {{ text }}
        </span>
      </div>
    </mds-label>
  </div>
</template>

<script>
import MdsLabel from '@mds/label';
import MdsRadioButton from '@mds/radio-button';

export default {
  name: 'AppBooleanSelector',
  components: {
    MdsLabel,
    MdsRadioButton,
  },
  props: {
    value: { type: Boolean, default: null },
    required: { type: Boolean, default: false },
    label: { type: String, default: '' },
    error: { type: Boolean, default: false },
    errorText: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      isTrue: this.value === null || !this.value ? false : true,
      isFalse: this.value === null || this.value ? false : true,
    };
  },
  methods: {
    trueSelected() {
      this.isTrue = true;
      this.isFalse = false;
      this.$emit('change', true);
    },
    falseSelected() {
      this.isTrue = false;
      this.isFalse = true;
      this.$emit('change', false);
    },
  },
};
</script>

<style lang="scss">
@import 'node_modules/@mds/field-error/src/field-error.scss';
.mds-input__field-error-wrapper-dp {
  margin-top: 4px;
}
</style>
