<template>
  <app-masthead>
    <template v-slot:mds-page-shell-vertical-nav>
      <WorkingDashboardNav />
    </template>
    <mds-loader v-if="isLoadingAuth"></mds-loader>
    <div v-else-if="!hasAccess">Sorry, you don't have write access.</div>
    <div v-else class="container">
      <h3 class="title">
        Batch Mapping
      </h3>
      <mds-form onsubmit="return false">
        <mds-layout-grid>
          <mds-row class="search-row" align-vertical="flex-start">
            <mds-col>
              <mds-combo-box
                label="All entries in these classes"
                multiple
                v-model="labels"
                :dataSet="classOptions"
              ></mds-combo-box>
            </mds-col>
            <mds-col>
              <div class="bold label-spacing">
                and are included in below collections
              </div>
              <TermSearchField
                placeholder="Search and add collections..."
                :onSelectRoute="false"
                :labelFilter="new Set(['collection'])"
                @selected="addCollection"
              />
              <mds-tag-container
                class="tag-spacing"
                v-for="(item, index) in selectedCollections"
                :key="index"
              >
                <mds-tag
                  removable
                  :on-light="true"
                  @mds-tag-removed="removeCollection(index)"
                >
                  {{ item.name }}
                </mds-tag>
              </mds-tag-container>
            </mds-col>
            <mds-col>
              <div class="bold label-spacing">
                will be mapped to below concepts
              </div>
              <TermSearchField
                placeholder="Search and add concepts..."
                :onSelectRoute="false"
                :labelFilter="new Set(['concept'])"
                @selected="addConcept"
              />
              <mds-tag-container
                class="tag-spacing"
                v-for="(item, index) in selectedConcepts"
                :key="index"
              >
                <mds-tag
                  removable
                  :on-light="true"
                  @mds-tag-removed="removeConcept(index)"
                >
                  {{ item.name }}
                </mds-tag>
              </mds-tag-container>
            </mds-col>
          </mds-row>
        </mds-layout-grid>
        <mds-button-container>
          <mds-button
            class="tag-spacing"
            text="Preview"
            type="button"
            @click="preview"
            :disabled="isPreviewDisabled || isPreviewing"
            :loading="isPreviewing"
          >
          </mds-button>
          <mds-button
            class="tag-spacing"
            text="Submit"
            type="button"
            variation="primary"
            @click="submit"
            :disabled="isSubmitDisabled || isSubmitting"
            :loading="isSubmitting"
          >
          </mds-button>
        </mds-button-container>
      </mds-form>
    </div>
  </app-masthead>
</template>

<script>
import AppMasthead from '@/components/elements/AppMasthead.vue';
import Auth from '@/js/auth.js';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import { MdsCol, MdsLayoutGrid, MdsRow } from '@mds/layout-grid';
import MdsComboBox from '@mds/combo-box';
import MdsForm from '@mds/form';
import MdsLoader from '@mds/loader';
import { MdsTag, MdsTagContainer } from '@mds/tag';
import Request from '@/js/request.js';
import WorkingDashboardNav from './WorkingDashboardNav.vue';
import TermSearchField from '@/components/TermManagement/TermSearchField.vue';
import Utils from '@/js/utils.js';

export default {
  name: 'WorkingDashboard',
  components: {
    AppMasthead,
    WorkingDashboardNav,
    MdsButton,
    MdsButtonContainer,
    MdsCol,
    MdsComboBox,
    MdsForm,
    MdsLayoutGrid,
    MdsLoader,
    MdsRow,
    MdsTag,
    MdsTagContainer,
    TermSearchField,
  },
  mixins: [Auth, Utils],
  data() {
    return {
      isPreviewing: false,
      isSubmitting: false,
      isLoadingAuth: false,
      selectedConcepts: [],
      selectedCollections: [],
      labels: ['article', 'video'],
      classOptions: [],
      downloadUrl: null,
    };
  },
  mounted: async function() {
    this.isLoadingAuth = true;
    await this.isAuthenticated();
    if (this.hasAccess) {
      await this.loadClassOptions();
    }
    this.isLoadingAuth = false;
  },
  methods: {
    async submit() {
      const payload = { s3Url: this.downloadUrl };
      this.isSubmitting = true;
      const response = await Request.put('/mapping', payload);
      if (response !== undefined) {
        this.notifySuccess('Successfully added relationships.');
      }
      this.isSubmitting = false;
    },
    async preview() {
      const conceptIds = [];
      const collectionIds = [];
      this.selectedConcepts.forEach(element => {
        conceptIds.push(element.id);
      });
      this.selectedCollections.forEach(element => {
        collectionIds.push(element.id);
      });
      const payload = {
        conceptIds: conceptIds,
        collectionIds: collectionIds,
        labels: this.labels,
      };
      this.isPreviewing = true;
      this.downloadUrl = await Request.put('/mapping', payload);
      this.isPreviewing = false;
      window.open(this.downloadUrl);
    },
    async loadClassOptions() {
      await this.$store.dispatch('getAllClasses');
      let classOptions = [];
      for (let key in this.$store.state.schema.classMap) {
        classOptions.push({
          value: key,
          text: this.decamelize(key),
        });
      }
      this.classOptions = classOptions;
    },
    addCollection(collection) {
      this.selectedCollections.push(collection);
    },
    addConcept(concept) {
      this.selectedConcepts.push(concept);
    },
    removeCollection(index) {
      this.selectedCollections.splice(index, 1);
    },
    removeConcept(index) {
      this.selectedConcepts.splice(index, 1);
    },
  },
  computed: {
    hasAccess() {
      return this.userGroup === 'admin' || this.userGroup === 'writer';
    },
    isPreviewDisabled() {
      return (
        this.selectedConcepts.length === 0 ||
        this.selectedCollections.length === 0 ||
        this.labels.length === 0
      );
    },
    isSubmitDisabled() {
      return this.downloadUrl === null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/global.scss';
.label-spacing {
  margin: #{$mds-space-half-x} 0 #{$mds-space-half-x} 0;
}
.tag-spacing {
  margin: #{$mds-space-1-x} 0 0 0;
}
</style>
