<template>
  <mds-list-group-item
    :text="termObject.name"
    :metadata-below="resultMetadata()"
    @mds-list-item-clicked="click"
  >
  </mds-list-group-item>
</template>

<script>
import { MdsListGroupItem } from '@mds/list-group';
import Constants from '@/js/constants.js';
import Utils from '@/js/utils.js';

export default {
  name: 'AppTermSummary',
  components: { MdsListGroupItem },
  props: {
    termObject: Object,
  },
  mixins: [Constants, Utils],
  methods: {
    resultMetadata() {
      let result = [this.decamelize(this.termObject[this.PPT_LABEL])];
      if (this.termObject.hasOwnProperty(this.PPT_IDENTIFIER)) {
        result.push(this.termObject[this.PPT_IDENTIFIER]);
      }
      if (this.termObject.hasOwnProperty(this.PPT_PUBLISH_DATE)) {
        result.push(this.termObject[this.PPT_PUBLISH_DATE].substring(0, 10));
      }
      if (this.termObject.hasOwnProperty(this.PPT_TRANSLATED)) {
        result.push(this.termObject[this.PPT_TRANSLATED].split(';')[0]);
      }
      return result;
    },
    click() {
      this.$emit('click');
    },
  },
};
</script>
