import EventBus from '@/js/event-bus.js';
import moment from 'moment';
import Request from '@/js/request.js';

export default {
  methods: {
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD');
    },
    formatDateMMDDYYYY(date) {
      if (date) return moment(date).format('MMM DD, YYYY');
      return date;
    },
    camelize(str) {
      return str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
          return index === 0 ? word.toLowerCase() : word.toUpperCase();
        })
        .replace(/\s+/g, '');
    },
    decamelize(string) {
      if (!string) return;
      string = string.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
      string = string.replace(/([A-Z])([A-Z])([a-z0-9])/g, '$1 $2$3');
      return this.capitalizeFirstLetter(string);
    },
    capitalizeFirstLetter(string) {
      return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
    },
    deepCopy(target) {
      if (!target) return;
      return JSON.parse(JSON.stringify(target));
    },
    routeToPage(routerName, query = {}, params = {}, openInNewTab = false) {
      console.log('routeToPage', routerName, query, params);
      if (openInNewTab) {
        let routeData = this.$router.resolve({
          name: routerName,
          query: query,
          params: params,
        });
        window.open(routeData.href, '_blank');
      } else {
        this.$router
          .push({
            name: routerName,
            query: query,
            params: params,
          })
          .catch(() => {});
      }
    },
    notifyError(message, title = '') {
      EventBus.$emit('addNotification', {
        variation: 'error',
        title: title,
        message: message,
      });
    },
    notifySuccess(message, title = '') {
      EventBus.$emit('addNotification', {
        variation: 'success',
        title: title,
        message: message,
      });
    },
    notifyWarning(message, title = '') {
      EventBus.$emit('addNotification', {
        variation: 'warning',
        title: title,
        message: message,
      });
    },
    validateSlugFormat(text) {
      if (text) {
        let re = /^[a-z0-9-]+$/;
        return re.test(text);
      }
      return true;
    },
    async isExist(label, field, value) {
      const result = await Request.get(
        '/query',
        {
          query: `g.V().hasLabel('${label}').has('${field}', '${value}').count()`,
        },
        true,
      );
      if (result[0] > 0) return true;
      return false;
    },
  },
};
