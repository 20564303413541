import { render, staticRenderFns } from "./SchemaNav.vue?vue&type=template&id=e65b0134&scoped=true&"
import script from "./SchemaNav.vue?vue&type=script&lang=js&"
export * from "./SchemaNav.vue?vue&type=script&lang=js&"
import style0 from "./SchemaNav.vue?vue&type=style&index=0&id=e65b0134&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e65b0134",
  null
  
)

export default component.exports