<template>
  <app-masthead>
    <template v-slot:mds-page-shell-vertical-nav>
      <WorkingDashboardNav />
    </template>
    <div class="container">
      <h3 class="title">
        Unmapped
        {{ activeClass.charAt(0).toUpperCase() + activeClass.slice(1) }}
      </h3>
      <mds-loader v-if="isLoading"></mds-loader>
      <mds-select
        v-if="!isLoading"
        label="Locale"
        :options="locales"
        v-model="selectedLocale"
        @change="getData"
      ></mds-select>
      <mds-data-table
        v-if="!isLoading"
        row-hover
        :header-configs="headers"
        :row-data="rows"
        @mds-data-table-header-action-click="onPopoverActionClick"
      >
      </mds-data-table>
      <mds-popover
        triggered-by="date-filter"
        position="bottom-center"
        v-model="model1"
        width="300px"
        title="Filter"
      >
        <template v-slot:mds-popover-actions>
          <mds-button size="medium" @click="clearSelection">Clear</mds-button>
        </template>
        <AppDatePicker v-bind="startDate" @selected="selectedStartDate" />
        -
        <AppDatePicker
          class="clicked"
          v-bind="endDate"
          @selected="selectedEndDate"
        />
      </mds-popover>
      <span>
        <mds-pagination
          v-if="count >= pageSize"
          show-items-info
          show-items-select
          :page="currentPage"
          :total-items="count"
          :pageSize="pageSize"
          @mds-pagination-page-changed="pageChanged"
        ></mds-pagination>
      </span>
    </div>
  </app-masthead>
</template>

<script>
import AppMasthead from '@/components/elements/AppMasthead.vue';
import AppDatePicker from '@/components/elements/AppDatePicker.vue';
import Auth from '@/js/auth.js';
import { MdsButton } from '@mds/button';
import { MdsDataTable } from '@mds/data-table';
import MdsLoader from '@mds/loader';
import MdsPagination from '@mds/pagination';
import MdsPopover from '@mds/popover';
import Request from '@/js/request.js';
import WorkingDashboardNav from './WorkingDashboardNav.vue';
import MdsSelect from '@mds/select';
import Utils from '@/js/utils.js';
import _ from 'underscore';

export default {
  name: 'WorkingDashboard',
  components: {
    AppMasthead,
    AppDatePicker,
    WorkingDashboardNav,
    MdsButton,
    MdsLoader,
    MdsDataTable,
    MdsPagination,
    MdsPopover,
    MdsSelect,
  },
  mixins: [Auth, Utils],
  data() {
    return {
      results: [],
      locales: [],
      headers: [
        {
          fieldName: 'name',
          text: 'Name',
          width: '70%',
        },
        {
          fieldName: 'date',
          text: 'Update/Publish Date',
          width: '30%',
          align: 'right',
          actionItems: [{ icon: 'funnel', id: 'date-filter' }],
        },
      ],
      count: 0,
      pageSize: 20,
      isLoading: false,
      isSearching: false,
      model1: false,
      startDate: this.getStartDate(),
      endDate: this.getEndDate(),
      selectedLocale: 'en-US',
    };
  },
  async mounted() {
    this.updateTitle();
    this.loadLocales();
    await this.isAuthenticated();
    await this.getData();
  },
  methods: {
    updateTitle() {
      if (this.activeClass) {
        document.title = `Unmapped ${this.decamelize(this.activeClass)}  | ${
          process.env.VUE_APP_TITLE
        }`;
      }
    },
    async loadLocales() {
      let response = await Request.get(
        '/schema/' + this.activeClass.slice(0, -1),
        this.ignoreCache,
      );

      response['attributes'].forEach(element => {
        if (element.name == 'locale') {
          let locals = element['predefinedList'].split(',');
          for (let local in locals) {
            this.locales.push({
              value: locals[local],
              text: locals[local],
            });
          }
        }
      });
      this.locales = _.sortBy(this.locales, 'value');
    },
    async dashboard(parameters) {
      this.isLoading = true;
      const parameters_ = {};
      for (let key in parameters) {
        if (parameters[key] && parameters[key] !== '')
          parameters_[key] = parameters[key];
      }
      if (this.isSearching) return;
      this.isSearching = true;

      this.results = await Request.get(
        '/dashboard',
        parameters,
        this.ignoreCache,
      );

      this.isSearching = false;
      this.count = this.results.count;
      this.isLoading = false;
    },
    async getData() {
      let parameters = {
        label: this.activeClass.slice(0, -1),
        start: 0,
        end: 20,
        locale: this.selectedLocale,
      };
      await this.dashboard(parameters);
    },

    async pageChanged(payload) {
      if (payload.pageSize != this.pageSize) {
        this.pageSize = payload.pageSize;
      }
      let parameters = {
        label: this.activeClass.slice(0, -1),
        start: payload.firstItem - 1,
        end: payload.lastItem,
        locale: this.selectedLocale,
      };
      await this.dashboard(parameters);
    },
    onPopoverActionClick(ev) {
      if (ev.id == 'date-filter') {
        if (this.model1 == true) {
          this.model1 = false;
        } else {
          this.model1 = true;
        }
        return;
      }
    },
    getStartDate() {
      return {
        value:
          this.value && this.value.hasOwnProperty('gte')
            ? new Date(`${this.value['gte']}T00:00:00`)
            : null,
        placeholder: 'From',
      };
    },
    getEndDate() {
      return {
        value:
          this.value && this.value.hasOwnProperty('lte')
            ? new Date(`${this.value['lte']}T00:00:00`)
            : null,
        placeholder: 'To',
      };
    },
    selectedStartDate(date) {
      this.startDate.value = date;
      this.updateDate();
    },
    selectedEndDate(date) {
      this.endDate.value = date;
      this.updateDate();
    },
    updateDate() {
      if (
        this.startDate.value &&
        this.endDate.value &&
        this.startDate.value > this.endDate.value
      ) {
        this.$set(this.startDate, 'error', true);
        this.$set(this.endDate, 'error', true);
        this.$set(this.endDate, 'errorText', [
          'The start date should be earlier than the end date.',
        ]);
      } else {
        this.$set(this.startDate, 'error', false);
        this.$set(this.endDate, 'error', false);
      }
      if (this.startDate.value < this.endDate.value) {
        this.updateTable(this.startDate.value, this.endDate.value);
      }
    },
    async updateTable(startDate, endDate) {
      let parameters = {
        label: this.activeClass.slice(0, -1),
        from: this.formatDate(startDate),
        to: this.formatDate(endDate),
        locale: this.selectedLocale,
      };
      await this.dashboard(parameters);
    },
    async clearSelection() {
      this.startDate.value = '';
      this.endDate.value = '';
      let parameters = {
        label: this.activeClass.slice(0, -1),
        start: 0,
        end: 20,
        locale: this.selectedLocale,
      };
      await this.dashboard(parameters);
    },
  },
  computed: {
    rows() {
      let result = [];
      for (let key in this.results.items) {
        result.push({
          name: `<a href="/term/view/${this.results.items[key].id}" target="_blank" style="color: black;">${this.results.items[key].name}</a>`,
          date:
            this.results.items[key].date.split('-')[1] +
            '/' +
            this.results.items[key].date.split('-')[2].split('T')[0] +
            '/' +
            this.results.items[key].date.split('-')[0],
          id: key,
        });
      }
      return result;
    },
    activeClass() {
      return this.$route.params.className;
    },
    currentPage() {
      if (this.results.hasOwnProperty('start')) {
        return Math.floor(this.results.start / 20) + 1;
      } else {
        return 1;
      }
    },
  },
  watch: {
    async activeClass() {
      this.updateTitle();
      await this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/global.scss';
@import '@mds/constants';
@import '@mds/typography';

.container {
  margin-top: -#{$mds-space-2-x};
}
.title {
  @include mds-level-5-heading($bold: true);
}
.clicked {
  height: 250px;
}
</style>
