<template>
  <mds-module-container class="relationship-margin">
    <mds-section
      :collapsible="true"
      :expanded="true"
      :bold="true"
      :title="cardTitle"
      :size="7"
      borderPosition="bottom"
    >
      <template v-slot:mds-section-title-description>
        <mds-button
          v-if="required"
          class="mds-label__requird-indicator"
          variation="flat"
          type="button"
          text="*"
        >
        </mds-button>
        <mds-button
          class="info__button"
          v-if="classDescription !== null"
          variation="icon-only"
          icon="info-circle"
          size="small"
          type="button"
          @mouseover="toggle = true"
          @mouseleave="toggle = false"
          :id="targetClass"
          >Open Popover</mds-button
        >
        <mds-popover
          v-if="classDescription !== null"
          :triggered-by="targetClass"
          v-model="toggle"
          title-hidden
          position="bottom-right"
        >
          {{ classDescription }}
        </mds-popover>
      </template>
      <template v-slot:mds-section-supplemental-content>
        <div v-if="error" class="mds-input__field-error-wrapper">
          <span
            class="mds-field-error"
            v-for="(text, index) in errorText"
            :key="index"
          >
            {{ text }}
          </span>
        </div>
      </template>
      <mds-list-group v-if="relationships">
        <mds-list-group-item
          class="full-width"
          v-for="(item, index) in relationships"
          :key="index"
        >
          <mds-button
            variation="flat"
            :text="item.name"
            @click="routeToTerm($event, item)"
          >
          </mds-button>
          <mds-button
            v-if="!readOnly"
            class="float-right"
            variation="icon-only"
            icon="remove"
            @click="removed(item)"
          >
          </mds-button>
        </mds-list-group-item>
      </mds-list-group>
      <mds-pagination
        show-items-info
        v-if="count > pageSize"
        :total-items="count"
        :pageSize="pageSize"
        @mds-pagination-page-changed="pageChanged($event)"
      ></mds-pagination>
      <div v-if="removedList.length > 0">
        <h4 class="h4-margin">Relationships to be removed:</h4>
        <mds-list-group>
          <mds-list-group-item
            class="full-width"
            v-for="(item, index) in removedList"
            :key="index"
          >
            <mds-button
              variation="flat"
              :text="item.name"
              @click="routeToTerm($event, item)"
            >
            </mds-button>
            <mds-button
              class="float-right"
              variation="icon-only"
              icon="remove"
              @click="cancelRemove(item)"
            >
            </mds-button>
          </mds-list-group-item>
        </mds-list-group>
      </div>
      <TermSearchField
        v-if="!readOnly"
        :onSelectRoute="false"
        :labelFilter="new Set([targetClass])"
        placeholder="Search and add..."
        @selected="added($event)"
        class="add-search-field"
      />
      <div v-if="addedList.length > 0">
        <h4 class="h4-margin">Relationships to be added:</h4>
        <mds-list-group>
          <mds-list-group-item
            class="full-width"
            v-for="(item, index) in addedList"
            :key="index"
          >
            <mds-button
              variation="flat"
              :text="item.name"
              @click="routeToTerm($event, item)"
            >
            </mds-button>
            <mds-button
              class="float-right"
              variation="icon-only"
              icon="remove"
              @click="cancelAdd(item)"
            >
            </mds-button>
          </mds-list-group-item>
        </mds-list-group>
      </div>
    </mds-section>
  </mds-module-container>
</template>

<script>
import { MdsButton } from '@mds/button';
import Constants from '@/js/constants.js';
import { MdsListGroup, MdsListGroupItem } from '@mds/list-group';
import MdsModuleContainer from '@mds/module-container';
import MdsSection from '@mds/section';
import MdsPagination from '@mds/pagination';
import MdsPopover from '@mds/popover';
import TermSearchField from './TermSearchField.vue';
import Utils from '@/js/utils.js';

export default {
  name: 'TermEditRelationship',
  components: {
    MdsButton,
    MdsListGroup,
    MdsListGroupItem,
    MdsModuleContainer,
    MdsSection,
    MdsPagination,
    MdsPopover,
    TermSearchField,
  },
  mixins: [Constants, Utils],
  props: {
    id: { type: String, default: null },
    name: { type: String, required: true },
    description: { type: String, required: true },
    required: { type: Boolean, required: true },
    targetClass: { type: String, required: true },
    value: { type: Array, default: () => [] },
    count: { type: Number, default: 0 },
    readOnly: { type: Boolean, required: true },
    existingTaxonomy: { type: Object, required: false, default: null },
    powerup: { type: Boolean, default: false },
    classDescription: { type: String, required: false, default: null },
  },
  /** This can't be async, otherwise there will be a delay between the page loading and existingTaxonomy being attached. */
  created: function() {
    // if existingTaxonomy exists, iterate through and add to page.
    if (this.existingTaxonomy && this.existingTaxonomy.taxonomy) {
      try {
        const taxonomy = this.existingTaxonomy.taxonomy;
        for (const [, value] of Object.entries(taxonomy)) {
          if (value['taxonomy_class'] === this.targetClass) {
            let object = {
              name: value['taxonomy_name'],
              id: value['taxonomy_id'],
              label: value['taxonomy_class'],
            };
            this.added(object);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  },
  data() {
    return {
      toggle: false,
      relationships: this.value,
      addedList: [],
      removedList: [],
      addedSet: new Set(),
      removedSet: new Set(),
      error: false,
      errorText: ['This field is required'],
      pageSize: 20,
    };
  },
  methods: {
    added(newValue) {
      if (this.addedSet.has(newValue.id)) {
        this.notifyWarning(
          `${newValue.name} is in the list to be added.`,
          'Duplicate Value Skipped',
        );
        return;
      }

      if (this.relationships) {
        const foundIndex = this.relationships.findIndex(
          element => element.id === newValue.id,
        );
        if (foundIndex != -1) {
          this.notifyWarning(
            `${newValue.name} is in the relationship list.`,
            'Duplicate Value Skipped',
          );
          return;
        }
      }
      this.addedList.push(newValue);
      this.addedSet.add(newValue.id);
      this.emitChange();
    },
    removed(newValue) {
      if (this.removedSet.has(newValue.id)) {
        this.notifyWarning(
          `${newValue.name} is in the list to be removed.`,
          'Duplicate Value Skipped',
        );
        return;
      }
      this.removedList.push(newValue);
      this.removedSet.add(newValue.id);
      this.emitChange();
    },
    cancelAdd(item) {
      const index = this.addedList.findIndex(obj => obj.id === item.id);
      this.addedList.splice(index, 1);
      this.addedSet.delete(item.id);

      this.emitChange();
    },
    cancelRemove(item) {
      const index = this.removedList.findIndex(obj => obj.id === item.id);
      this.removedList.splice(index, 1);
      this.removedSet.delete(item.id);
      this.emitChange();
    },
    emitChange() {
      this.$emit('change', {
        relationships_add: this.addedList,
        relationships_delete: this.removedList,
      });
    },
    // if this is the new taxonomy powerup, open up panel
    // event.pointerType == mouse;
    routeToTerm(event, termObject) {
      if (this.powerup && event.pointerType === 'mouse')
        this.$emit('route', termObject);
      else if (!this.powerup)
        this.routeToPage('TermView', {}, { entryId: termObject.id }, true);
    },
    async pageChanged(payload) {
      const request = {
        id: this.id,
        edge: this.name,
        vertex: this.targetClass,
        start: payload['firstItem'],
        end: payload['lastItem'],
        refresh: true,
      };
      await this.$store.dispatch('getRelationshipPagination', request);
      this.relationships = this.$store.state.term.termObject['relationships'][
        this.name
      ][this.targetClass];
    },
    validate() {
      if (!this.required) return '';
      if (this.count + this.addedList.length - this.removedList.length === 0) {
        this.error = true;
        return `${this.cardTitle}, `;
      }
      this.error = false;
      return '';
    },
  },
  computed: {
    /** Every class should be the same, but 'Branding' should be 'Fund Family' */
    cardTitle() {
      if (this.targetClass === 'branding')
        return `${this.description} - Fund Family`;
      else return `${this.description} - ${this.decamelize(this.targetClass)}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/global.scss';
@import 'node_modules/@mds/field-error/src/field-error.scss';
.info__button {
  margin-left: #{$mds-space-quarter-x};
}
.relationship-margin {
  margin-bottom: #{$mds-space-2-and-a-half-x};
}
.add-search-field {
  width: 50%;
  margin-top: #{$mds-space-2-x};
}
.h4-margin {
  margin-bottom: #{$mds-space-1-x};
}
.mds-label__requird-indicator {
  color: $mds-form-text-color-field-error;
  text-decoration: none;
}
</style>
