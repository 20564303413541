<template>
  <div>
    <mds-button :text="termObject.name" variation="flat" @click="routeToTerm">
    </mds-button>
    <template
      v-if="termObject.label == REL_ARTICLE || termObject.label == REL_VIDEO"
      >{{ this.displayDate }}</template
    >
  </div>
</template>

<script>
import { MdsButton } from '@mds/button';
import Utils from '@/js/utils.js';
import Constants from '@/js/constants.js';

export default {
  components: { MdsButton },
  name: 'TermViewRelationship',
  props: {
    termObject: Object,
  },
  mixins: [Utils, Constants],
  methods: {
    async routeToTerm() {
      this.routeToPage('TermView', {}, { entryId: this.termObject.id });
    },
  },
  computed: {
    displayDate() {
      if (this.termObject.hasOwnProperty(this.PPT_SOURCE_UPDATE_DATE)) {
        return `(${this.formatDateMMDDYYYY(this.termObject.sourceUpdateDate)})`;
      } else if (
        !this.termObject.hasOwnProperty(this.PPT_SOURCE_UPDATE_DATE) &&
        this.termObject.hasOwnProperty(this.PPT_PUBLISH_DATE)
      ) {
        return `(${this.formatDateMMDDYYYY(this.termObject.publishDate)})`;
      }
      return '';
    },
  },
};
</script>
