<template>
  <nav>
    <mds-section title="Concept Hierarchy">
      <mds-select
        class="add-filter-select-spacing"
        v-for="(options, index) in newOptionsList"
        v-model="selected[index]"
        :key="index"
        :label="`Level ${index + 1}`"
        :options="options"
        @change="selectedChanged(index)"
        @click="
          routeToPage('ConceptHierarchy', {}, { conceptId: selected[index] })
        "
      ></mds-select>
    </mds-section>
    <div v-if="relatedTerms.length > 0">
      <span class="bold">{{ selectedText }} Related Terms:</span>
      <ul v-for="item in relatedTerms" :key="item">
        <li>{{ item }}</li>
      </ul>
    </div>
  </nav>
</template>

<script>
import MdsSection from '@mds/section';
import MdsSelect from '@mds/select';
import Utils from '@/js/utils.js';

export default {
  name: 'ConceptHierarchyNav',
  components: {
    MdsSection,
    MdsSelect,
  },
  mixins: [Utils],
  props: {
    optionsList: {
      type: [String, Array],
      default: '',
    },
    nextTerms: {
      type: [String, Array],
    },
    relatedTermsCache: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selected: [],
      newOptionsList: [],
      selectedText: null,
      selectedId: null,
    };
  },
  methods: {
    selectedChanged(index) {
      const concept = this.optionsList[index].find(
        item => item.value === this.selected[index],
      );
      this.$emit('change', {
        idx: index,
        value: this.selected[index],
        name: concept.text,
        isEnd: concept.isEndNode,
      });
    },
    updateList() {
      const curLevel = this.selected.length - 1;
      let selectedText = null;
      let selectedId = null;
      let newOptionsList = [];
      for (let i in this.optionsList) {
        let temp = [];
        for (let j in this.optionsList[i]) {
          const termText = this.optionsList[i][j].text.split(';')[0];
          const termId = this.optionsList[i][j].value;
          temp.push({ text: termText, value: termId });
          if (
            curLevel >= 0 &&
            i === curLevel.toString() &&
            termId === this.selected[curLevel]
          ) {
            selectedText = termText;
            selectedId = termId;
          }
        }
        newOptionsList.push(temp);
      }
      this.newOptionsList = newOptionsList;
      this.selectedText = selectedText;
      this.selectedId = selectedId;
    },
  },
  computed: {
    relatedTerms() {
      if (
        this.selectedId !== null &&
        this.relatedTermsCache.hasOwnProperty(this.selectedId)
      ) {
        return this.relatedTermsCache[this.selectedId];
      }
      return [];
    },
  },
  watch: {
    nextTerms() {
      this.selected = this.nextTerms;
    },
    optionsList() {
      this.updateList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/global.scss';
@import '@mds/constants';

.add-filter-select-spacing {
  margin: #{$mds-space-1-x} 0 #{$mds-space-1-x} 0;
}
</style>
