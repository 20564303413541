<template>
  <div>
    <!-- cannot select month and year if put datepicker in mds-label -->
    <mds-label
      class="label-margin-dp"
      v-if="label"
      :required="required"
      :text="label"
    >
    </mds-label>
    <datepicker
      format="MM/dd/yyyy"
      :typeable="true"
      :calendar-class="'narrow-calendar'"
      :input-class="inputClass"
      :value="value"
      :disabled="disabled"
      :placeholder="placeholder"
      @selected="selected"
    >
    </datepicker>
    <div v-if="error" class="mds-input__field-error-wrapper-dp">
      <span
        class="mds-field-error"
        v-for="(text, index) in errorText"
        :key="index"
      >
        {{ text }}
      </span>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import MdsLabel from '@mds/label';

export default {
  name: 'AppDatePicker',
  components: {
    Datepicker,
    MdsLabel,
  },
  props: {
    value: { type: Date, default: null },
    placeholder: { type: String, default: '' },
    required: { type: Boolean, default: false },
    label: { type: String, default: '' },
    error: { type: Boolean, default: false },
    errorText: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
  },
  methods: {
    selected(newValue) {
      this.$emit('selected', newValue);
    },
  },
  computed: {
    inputClass() {
      return this.error ? 'mds-input-dp-error' : 'mds-input-dp';
    },
  },
};
</script>

<style lang="scss">
@import 'node_modules/@mds/input/src/input.scss';
@import 'node_modules/@mds/field-error/src/field-error.scss';
.label-margin-dp {
  margin-bottom: 0px !important;
}
.narrow-calendar {
  width: 200px !important;
  & .cell {
    height: 30px !important;
    line-height: 30px !important;
  }
}
.mds-input-dp {
  @extend .mds-input;
  background-color: #e5e5e5 !important;
  box-shadow: inset 0 -1px 0 0 #808080 !important;
  padding: 0 8px !important;
  &:hover {
    box-shadow: $mds-form-box-shadow-hover !important;
  }
  &:focus {
    box-shadow: $mds-form-box-shadow-focus, $mds-form-box-shadow-hover !important;
    outline: none;
  }
}
.mds-input-dp-error {
  @extend .mds-input;
  background-color: #e5e5e5 !important;
  box-shadow: $mds-form-box-shadow-error !important;
  padding: 0 8px !important;
  &:hover {
    box-shadow: $mds-form-box-shadow-error !important;
  }
  &:focus {
    box-shadow: $mds-form-box-shadow-error, $mds-form-box-shadow-focus !important;
    outline: none;
  }
}
.mds-input__field-error-wrapper-dp {
  margin-top: 4px;
}
</style>
