import axios from 'axios';
import Utils from '@/js/utils.js';
import Vue from 'vue';

export default {
  async get(requestURL, parameters, refresh = false) {
    try {
      let config = {
        params: parameters,
        headers: {
          'X-API-Key': process.env.VUE_APP_API_KEY,
          Authorization: `Bearer ${await this.getMassToken()}`,
        },
      };
      if (refresh) {
        config['headers']['Cache-Control'] = 'max-age=0';
      }
      const response = await axios.get(requestURL, config);
      return response.data;
    } catch (error) {
      return this.handleError(error);
    }
  },
  async post(requestURL, parameters) {
    try {
      let config = {
        headers: {
          'X-API-Key': process.env.VUE_APP_API_KEY,
          Authorization: `Bearer ${await this.getMassToken()}`,
        },
      };
      const response = await axios.post(
        requestURL,
        JSON.stringify(parameters),
        config,
      );

      return response.data;
    } catch (error) {
      return this.handleError(error);
    }
  },
  async put(requestURL, parameters) {
    try {
      let config = {
        headers: {
          'X-API-Key': process.env.VUE_APP_API_KEY,
          Authorization: `Bearer ${await this.getMassToken()}`,
        },
      };
      const response = await axios.put(
        requestURL,
        JSON.stringify(parameters),
        config,
      );
      return response.data;
    } catch (error) {
      return this.handleError(error);
    }
  },
  async delete(requestURL) {
    try {
      let config = {
        headers: {
          'X-API-Key': process.env.VUE_APP_API_KEY,
          Authorization: `Bearer ${await this.getMassToken()}`,
        },
      };
      const response = await axios.delete(requestURL, config);
      return response.data;
    } catch (error) {
      //TODO: Catch And Print "message": "Forbidden
      return this.handleError(error);
    }
  },
  async getMassToken() {
    try {
      let config = {
        headers: {},
      };

      if (process.env.VUE_APP_REQUIRES_AUTH !== 'false')
        config = {
          headers: {
            Authorization: `Bearer ${await Vue.prototype.$auth.getAccessToken()}`,
          },
        };

      const tokenUrl = `${process.env.VUE_APP_WEB_URL}/api/maas/token`;
      const response = await axios.get(tokenUrl, config);
      return response.data;
    } catch (error) {
      return this.handleError(error);
    }
  },
  handleError(error) {
    if (error.hasOwnProperty('response') && error.response) {
      let errorMessage = error.response.data.errorMessage;
      const errorType = error.response.data.errorType;
      Utils.methods.notifyError(errorMessage, errorType);
    } else if (error.hasOwnProperty('message') && error.message) {
      console.log(Object.keys(error));
      console.log('response', error['response']);
      Utils.methods.notifyError(error.message);
    }
    return undefined;
  },
};
