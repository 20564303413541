<template>
  <span>
    <mds-button
      :variation="variation"
      type="button"
      :id="id"
      :icon="icon"
      :disabled="disabled"
      :size="size"
      :flat-button-icon-size="flatButtonSize"
      @click="toggleTooltipModal"
      @blur="toggleTooltip = false"
      class="icon-button-text"
      >{{ text }}
    </mds-button>
    <mds-tooltip
      :triggered-by="id"
      :position="position"
      :size="size"
      v-model="toggleTooltip"
    >
      <span v-html="tooltip"></span>
    </mds-tooltip>
  </span>
</template>

<script>
import { MdsButton } from '@mds/button';
import MdsTooltip from '@mds/tooltip';
import { uuid } from 'vue-uuid';

export default {
  name: 'AppIconTooltipAndText',
  components: {
    MdsButton,
    MdsTooltip,
  },
  props: {
    icon: String,
    tooltip: String,
    disabled: { type: Boolean, default: false },
    size: { type: String, default: 'medium' },
    variation: { type: String, default: 'icon-only' },
    text: { type: String, default: '' },
    flatButtonSize: { type: String, default: 'medium' },
    position: { type: String, default: 'bottom-center' },
  },
  data() {
    return {
      toggleTooltip: false,
      id: uuid.v1(),
    };
  },
  methods: {
    toggleTooltipModal() {
      this.toggleTooltip = !this.toggleTooltip;
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss">
@import '@/style/global.scss';

.icon-button-text {
  span {
    margin: 0 #{$mds-space-quarter-x} 0 0 !important;
  }
}
</style>
