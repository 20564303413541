<template>
  <div id="app">
    <AppNotifications />
    <router-view></router-view>
  </div>
</template>

<script>
import AppNotifications from '@/components/elements/AppNotifications.vue';
export default {
  name: 'App',
  components: {
    AppNotifications,
  },
};
</script>

<style lang="scss" scoped>
#app {
  height: calc(100vh - 50px);
}
</style>

<style lang="scss">
@import '@/style/main.scss';
</style>

<style lang="scss" module>
@import '@/style/global.scss';
</style>
