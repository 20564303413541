<template>
  <div style="max-width: 400px">
    <mds-section title="Database Summary" bold border="none">
      <template v-slot:mds-section-actions>
        <AppIconTooltip
          class="mds-section-actions-spacing"
          icon="refresh"
          tooltip="refresh count"
          :disabled="isLoading"
          @click="load(true)"
        />
      </template>
    </mds-section>
    <mds-loader v-if="isLoading"></mds-loader>
    <div v-else-if="rows.length == 0">
      There are no classes in the database.
    </div>
    <mds-data-table
      v-else
      row-numbers
      row-hover
      :header-configs="headers"
      :row-data="rows"
      @mds-data-table-sort-change="onSort($event)"
    ></mds-data-table>
  </div>
</template>

<script>
import AppIconTooltip from '@/components/elements/AppIconTooltip.vue';
import { MdsDataTable } from '@mds/data-table';
import MdsLoader from '@mds/loader';
import MdsSection from '@mds/section';
import Utils from '@/js/utils.js';

export default {
  name: 'SchemaSummary',
  components: {
    AppIconTooltip,
    MdsDataTable,
    MdsLoader,
    MdsSection,
  },
  mixins: [Utils],
  async mounted() {
    await this.load();
  },
  props: {
    classMap: Object,
  },
  data() {
    return {
      headers: [
        {
          fieldName: 'className',
          text: 'Class Name',
          width: '70%',
          sortable: true,
        },
        {
          fieldName: 'number',
          text: 'Number of Entries',
          width: '30%',
          align: 'right',
          sortable: true,
        },
      ],
      currentCol: 'className',
      currentSort: 1,
      isLoading: false,
      summary: {},
    };
  },
  computed: {
    rows() {
      let result = [];
      for (let key in this.summary) {
        result.push({
          className: this.decamelize(key),
          number: this.summary[key] ?? 0,
          id: this.decamelize(key),
        });
      }
      return result.sort((a, b) =>
        a[`${this.currentCol}`] >= b[`${this.currentCol}`]
          ? this.currentSort
          : -this.currentSort,
      );
    },
  },
  methods: {
    onSort(arg) {
      this.currentSort = arg.sortOrder;
      this.currentCol = arg.sortField;
    },
    async load(refresh = false) {
      this.isLoading = true;
      await this.$store.dispatch('getDatabaseSummary', refresh);
      this.summary = this.$store.state.schema.databaseSummary;
      this.isLoading = false;
    },
  },
};
</script>
