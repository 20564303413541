<template>
  <div class="attribute-margin">
    <TranslationAdd
      v-if="isTranslation"
      @translation-add="translationChanged"
    />
    <AppTextBox
      v-if="inputData && !isTextArea"
      :data="inputData"
      v-bind="inputData"
      @change="inputChanged"
    >
    </AppTextBox>
    <AppTextArea
      v-if="inputData && isTextArea"
      :data="inputData"
      v-bind="inputData"
      @change="inputChanged"
    >
    </AppTextArea>
    <AppInputTags
      v-if="multiInputData"
      v-bind="multiInputData"
      @change="multiInputChanged"
    />
    <mds-select
      v-if="selectData"
      v-bind="selectData"
      v-model="selectData.value"
      @change="selectChanged"
    ></mds-select>
    <mds-combo-box
      v-if="multiSelectData"
      v-bind="multiSelectData"
      v-model="multiSelectData.value"
      @input="multiSelectChanged"
      multiple
    ></mds-combo-box>
    <AppDatePicker v-if="dateData" v-bind="dateData" @selected="dateChanged" />
    <AppBooleanSelector
      v-if="boolData"
      v-bind="boolData"
      @change="boolChanged"
    />
  </div>
</template>

<script>
import slugify from '@sindresorhus/slugify';
import Utils from '@/js/utils.js';
import AppBooleanSelector from '../elements/AppBooleanSelector.vue';
import AppDatePicker from '@/components/elements/AppDatePicker.vue';
import AppInputTags from '../elements/AppInputTags.vue';
import AppTextBox from '../elements/AppTextBox.vue';
import AppTextArea from '../elements/AppTextArea.vue';
import MdsComboBox from '@mds/combo-box';
import TranslationAdd from './TranslationAdd.vue';
import MdsSelect from '@mds/select';

export default {
  name: 'TermEditAttribute',
  components: {
    AppBooleanSelector,
    AppDatePicker,
    AppInputTags,
    AppTextBox,
    AppTextArea,
    MdsComboBox,
    MdsSelect,
    TranslationAdd,
  },
  mixins: [Utils],
  props: {
    type: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      required: true,
    },
    singleValue: {
      type: Boolean,
      required: true,
    },
    predefinedList: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Boolean, Array],
      default: '',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    adminOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      requiredError: 'This field is required',
      slugError: 'All lowercase letters, numbers and hyphens only',
      existError: 'Already exist, choose a different value',
      inputData: this.getInputData(),
      multiInputData: this.getMultiInputData(),
      selectData: this.getSelectData(),
      multiSelectData: this.getMultiSelectData(),
      dateData: this.getDateData(),
      boolData: this.getBoolData(),
      isTranslation: this.name === 'translation',
      isTextArea: this.name === 'useCaseText' || this.name === 'definitionText',
    };
  },
  methods: {
    getInputData() {
      const user = this.$store.state.user;
      let isDisabled = false;

      if (this.readOnly && this.readOnly === true) {
        isDisabled = true;
      } else if (this.adminOnly && this.adminOnly === true) {
        isDisabled = user.userGroup !== 'admin';
      }

      if (
        ['Free Text', 'Structured Text', 'URL'].includes(this.type) &&
        this.singleValue &&
        this.predefinedList === ''
      )
        return {
          name: this.name,
          label: this.description,
          required: this.required,
          value: this.value,
          disabled: isDisabled,
          tooltip:
            this.name === 'slug'
              ? 'If needed, you can edit the slug applying the following rules for its format: Replace spaces with hyphens; Omit apostrophes; The ampersand “&” must be spelled out “and”; The sign “%” must be spelled out “percent”.'
              : '',
        };
      return null;
    },
    getMultiInputData() {
      if (
        ['Free Text', 'Structured Text', 'URL'].includes(this.type) &&
        !this.singleValue &&
        this.predefinedList === ''
      )
        return {
          name: this.name,
          label: this.description,
          required: this.required,
          value: this.value === '' ? [] : this.value,
          disabled: this.readOnly,
          tooltip:
            this.name === 'synonym'
              ? 'Only create synonymous cross-references with accepted alternative names or spellings, or spelled-out versions of acronyms. Do not create terms for misspellings.'
              : '',
        };
      return null;
    },
    getSelectData() {
      if (
        ['Free Text', 'Structured Text', 'URL'].includes(this.type) &&
        this.singleValue &&
        this.predefinedList !== ''
      ) {
        let options = [''];
        this.predefinedList.split(',').forEach(element => {
          options.push({ text: element, value: element });
        });
        return {
          label: this.description,
          required: this.required,
          options: options,
          value: this.value,
          disabled: this.readOnly,
        };
      }
      return null;
    },
    getMultiSelectData() {
      if (
        ['Free Text', 'Structured Text', 'URL'].includes(this.type) &&
        !this.singleValue &&
        this.predefinedList !== ''
      ) {
        let options = [''];
        let predefinedList = this.predefinedList.split(',');
        predefinedList.forEach(element => {
          options.push({ text: element, value: element });
        });
        let value = [];
        // remove values that are not in the predefined list
        if (this.value !== '') {
          this.value.forEach(element => {
            if (predefinedList.includes(element)) {
              value.push(element);
            }
          });
        }
        return {
          label: this.description,
          dataSet: options,
          required: this.required,
          value: value,
          disabled: this.readOnly,
        };
      }
      return null;
    },
    getDateData() {
      if (['DateTime'].includes(this.type)) {
        let result = {
          label: this.description,
          required: this.required,
          disabled: this.readOnly,
        };
        if (this.value && this.value !== '') {
          if (this.name === 'updateDate') result.value = new Date();
          else result.value = new Date(this.value);
        }
        return result;
      }
      return null;
    },
    getBoolData() {
      if (['Bool'].includes(this.type)) {
        return {
          label: this.description,
          required: this.required,
          value: this.value === '' ? null : this.value,
          disabled: this.readOnly,
        };
      }
      return null;
    },
    inputChanged() {
      this.$emit('change', this.inputData.value);
      if (this.name == 'name') {
        const slugField = this.$parent.$children.find(
          element => element.name == 'slug',
        );

        if (slugField) {
          const slugged = slugify(this.inputData.value, {
            customReplacements: [
              ['%', '-percent-'],
              ["'", ''],
            ],
          });
          slugField.inputData.value = slugged;
          slugField.$emit('change', slugged);
        }
      }
    },
    multiInputChanged(newValue) {
      this.multiInputData.value = newValue;
      this.$emit('change', this.multiInputData.value);
    },
    selectChanged() {
      this.$emit('change', this.selectData.value);
    },
    multiSelectChanged() {
      this.$emit('change', this.multiSelectData.value);
    },
    translationChanged(translation) {
      let existingTranslations = this.multiInputData.value;
      existingTranslations.push(translation);
      this.multiInputChanged(existingTranslations);
    },
    dateChanged(newValue) {
      this.dateData.value = newValue;
      this.$emit('change', this.formatDate(newValue));
    },
    boolChanged(newValue) {
      this.boolData.value = newValue;
      this.$emit('change', newValue);
    },
    async validate() {
      const data =
        this.inputData ||
        this.multiInputData ||
        this.dateData ||
        this.selectData ||
        this.multiSelectData ||
        this.boolData;

      if (!this.required) return '';

      if (
        data.value === null ||
        data.value === undefined ||
        data.value === '' ||
        (Array.isArray(data.value) && data.value.length == 0)
      ) {
        data.error = true;
        this.$set(data, 'error', true);
        this.$set(data, 'errorText', [this.requiredError]);
        return `${this.description}, `;
      } else {
        const classField = this.$parent.$children.find(
          element => element.$attrs['name'] == 'taxonomyClass',
        );

        if (classField && classField.$attrs['value'] === 'concept') {
          let isExist = false;
          if (data.name === 'name') {
            if (data.value !== this.value) {
              isExist = await this.isExist('concept', 'name', data.value);
            }
          }

          if (data.name === 'slug') {
            if (!this.validateSlugFormat(data.value)) {
              data.error = true;
              this.$set(data, 'error', true);
              this.$set(data, 'errorText', [this.slugError]);
              return `${this.description}, `;
            } else {
              if (data.value !== this.value) {
                isExist = await this.isExist('concept', 'slug', data.value);
              }
            }
          }

          if (isExist) {
            data.error = true;
            this.$set(data, 'error', true);
            this.$set(data, 'errorText', [this.existError]);
            return `${this.description}, `;
          }
        }

        this.$set(data, 'error', false);
        return '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/global.scss';
.attribute-margin {
  margin-bottom: #{$mds-space-2-and-a-half-x};
}
</style>
