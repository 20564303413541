<template>
  <app-masthead>
    <mds-form>
      <mds-textarea
        v-model="query"
        label="Custom Query"
        placeholder="Enter your gremlin query here"
        microcopy-above='Not familiar with gremlin query? See <a href="http://www.kelvinlawrence.net/book/PracticalGremlin.html" target="_blank"> tutorial</a>.'
      ></mds-textarea>
      <mds-select
        v-model="selectedQueryId"
        label="Predefined Queries"
        placeholder="select..."
        :microcopy-above="queryDescription"
        :options="queriesOptions"
        @change="selectQuery"
      ></mds-select>
      <mds-button-container>
        <AppIconTooltip
          icon="plus"
          tooltip="create a new query"
          position="right-center"
          @click="createQuery"
        />
        <AppIconTooltip
          icon="pencil"
          tooltip="edit query"
          position="right-center"
          :disabled="selectedQueryId === ''"
          @click="editQuery"
        />
        <AppIconTooltip
          icon="remove"
          tooltip="delete query"
          position="right-center"
          :disabled="selectedQueryId === ''"
          @click="deleteQuerySubmit"
        />
      </mds-button-container>
      <mds-button
        class="bottom-margin"
        @click="submit"
        variation="primary"
        type="button"
        :loading="isLoading"
        :disabled="query === '' || isLoading"
      >
        Submit
      </mds-button>
      <br />
      <mds-loader v-if="isLoading"></mds-loader>
      <mds-empty-state
        v-else-if="!isValidResult"
        title="No Results"
        message="Start a new search."
      ></mds-empty-state>
      <div v-else-if="header">
        <mds-switch class="bottom-margin float-right" v-model="showJSON">
          Show JSON format
        </mds-switch>
        <br />
        <pre v-if="showJSON">{{ results }}</pre>
        <mds-data-table
          v-else
          row-numbers
          row-hover
          :header-configs="header"
          :row-data="results"
        >
        </mds-data-table>
      </div>
      <div v-else>
        Cannot process results to a table, show JSON format instead: <br />
        <vue-json-pretty :path="'res'" :data="results"> </vue-json-pretty>
      </div>
    </mds-form>
    <mds-modal
      :title="editModalTitle"
      v-model="editModal"
      :width="'600px'"
      action-required
    >
      <template v-slot:mds-modal-actions>
        <mds-button-container right-aligned>
          <mds-button
            @click="editModal = false"
            variation="secondary"
            type="button"
          >
            Cancel
          </mds-button>
          <mds-button
            @click="modifyQuerySubmit"
            variation="primary"
            type="button"
            :loading="isLoading"
            :disabled="isLoading || !editValidation"
          >
            Save
          </mds-button>
        </mds-button-container>
      </template>
      <mds-form class="long-form" onsubmit="return false">
        <mds-input v-model="newText" label="Name" required></mds-input>
        <mds-input v-model="newDescription" label="Description" required>
        </mds-input>
        <mds-textarea v-model="newQuery" label="Query" required></mds-textarea>
      </mds-form>
    </mds-modal>
  </app-masthead>
</template>

<script>
import AppIconTooltip from '@/components/elements/AppIconTooltip.vue';
import AppMasthead from '@/components/elements/AppMasthead.vue';
import Auth from '@/js/auth.js';
import EventBus from '@/js/event-bus.js';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import { MdsDataTable } from '@mds/data-table';
import MdsEmptyState from '@mds/empty-state';
import MdsForm from '@mds/form';
import MdsInput from '@mds/input';
import MdsModal from '@mds/modal';
import MdsLoader from '@mds/loader';
import MdsSelect from '@mds/select';
import MdsSwitch from '@mds/switch';
import MdsTextarea from '@mds/textarea';
import Request from '@/js/request.js';
import Constants from '@/js/constants.js';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
  name: 'CustomQuery',
  components: {
    AppIconTooltip,
    AppMasthead,
    MdsButton,
    MdsButtonContainer,
    MdsDataTable,
    MdsEmptyState,
    MdsForm,
    MdsInput,
    MdsModal,
    MdsLoader,
    MdsSelect,
    MdsSwitch,
    MdsTextarea,
    VueJsonPretty,
  },
  mixins: [Auth, Constants],
  async mounted() {
    await this.getQueries();
    await this.isAuthenticated();
  },
  data() {
    return {
      isLoading: false,
      showJSON: false,
      query: '',
      queryDescription: '',
      results: [],
      selectedQueryId: '',
      queriesMap: {},
      isCreateNewQuery: false,
      editModal: false,
      newText: '',
      newDescription: '',
      newQuery: '',
    };
  },
  methods: {
    checkUserAccess() {
      if (this.userGroup === undefined) {
        EventBus.$emit('addNotification', {
          variation: 'error',
          title: 'Access Denied',
          message: 'Please sign in to edit queries.',
        });
        return false;
      }
      if (this.userGroup !== 'admin') {
        EventBus.$emit('addNotification', {
          variation: 'error',
          title: 'Access Denied',
          message: 'You do not have admin access.',
        });
        return false;
      }
      return true;
    },
    async getQueries() {
      this.isLoading = true;
      let queries = await Request.get('/query', {});
      let queriesMap = {};
      queries.forEach(element => {
        queriesMap[element['id']] = {
          description: element['description'],
          query: element['query'],
          text: element['text'],
        };
      });
      this.queriesMap = queriesMap;
      this.isLoading = false;
    },
    editQuery() {
      if (!this.checkUserAccess()) return;
      this.isCreateNewQuery = false;
      this.editModal = true;
      this.newQuery = this.query;
      this.newText = this.queriesMap[this.selectedQueryId]['text'];
      this.newDescription = this.queriesMap[this.selectedQueryId][
        'description'
      ];
    },
    createQuery() {
      if (!this.checkUserAccess()) return;
      this.isCreateNewQuery = true;
      this.editModal = true;
      this.newQuery = this.query;
      this.newText = '';
      this.newDescription = '';
    },
    async modifyQuerySubmit() {
      if (!this.checkUserAccess()) return;
      if (this.isCreateNewQuery) {
        await this.createQuerySubmit();
      } else {
        await this.editQuerySubmit();
      }
    },
    async editQuerySubmit() {
      let parameters = {
        text: this.newText,
        description: this.newDescription,
        query: this.newQuery,
      };
      this.isLoading = true;
      let response_body = await Request.put(
        `/query/${this.selectedQueryId}`,
        parameters,
      );
      this.isLoading = false;
      if (response_body !== undefined) {
        this.updateQueriesMap(response_body);
        this.editModal = false;
      }
    },
    async createQuerySubmit() {
      let parameters = {
        text: this.newText,
        description: this.newDescription,
        query: this.newQuery,
      };
      this.isLoading = true;
      let response_body = await Request.post('/query', parameters);
      this.isLoading = false;
      if (response_body !== undefined) {
        this.updateQueriesMap(response_body);
        this.editModal = false;
      }
    },
    updateQueriesMap(value) {
      this.$set(this.queriesMap, value[this.PPT_ID], {
        text: value['text'],
        description: value['description'],
        query: value['query'],
      });
      setTimeout(async () => {
        this.selectedQueryId = value[this.PPT_ID];
        this.selectQuery(this.selectedQueryId);
      }, 1);
    },
    async deleteQuerySubmit() {
      EventBus.$emit('addNotification', {
        variation: 'info',
        message: 'Are your sure to delete this custom query?',
        action: this.deleteQueryAction,
        actionText: 'Yes',
      });
    },
    async deleteQueryAction() {
      if (!this.checkUserAccess()) return;
      this.isLoading = true;
      let response_body = await Request.delete(
        `/query/${this.selectedQueryId}`,
      );
      this.isLoading = false;
      if (response_body !== undefined) {
        this.$delete(this.queriesMap, this.selectedQueryId);
        EventBus.$emit('addNotification', {
          variation: 'success',
          message: 'Query deleted',
        });
        setTimeout(async () => {
          this.selectedQueryId = '';
        }, 1);
        this.queryDescription = '';
      }
    },
    async submit() {
      this.isLoading = true;
      this.results = await Request.get('/query', { query: this.query });
      this.isLoading = false;
    },
    selectQuery(query) {
      this.query = this.queriesMap[query]['query'];
      this.queryDescription = this.queriesMap[query]['description'];
    },
  },
  computed: {
    editModalTitle() {
      return this.isCreateNewQuery ? 'Create a New Query' : 'Edit Query';
    },
    editValidation() {
      return (
        this.newText !== '' &&
        this.newDescription !== '' &&
        this.newQuery !== ''
      );
    },
    isValidResult() {
      return Array.isArray(this.results) && this.results.length > 0;
    },
    header() {
      try {
        if (!this.isValidResult) return null;
        let headerSet = new Set();
        let headerList = [];
        for (let i = 0; i < this.results.length; i++) {
          if (typeof this.results[i] !== 'object') return null;
          for (let key in this.results[i]) {
            if (!headerSet.has(key)) {
              headerSet.add(key);
              headerList.push({
                fieldName: key,
                text: key,
              });
            }
          }
        }
        return headerList;
      } catch (error) {
        console.log('Cannot process result', error);
        return null;
      }
    },
    queriesOptions() {
      let result = [];
      for (let key in this.queriesMap) {
        result.push({
          text: this.queriesMap[key]['text'],
          value: key,
        });
      }
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/global.scss';

.bottom-margin {
  margin-bottom: #{$mds-space-2-x};
}
</style>
