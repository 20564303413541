import Constants from '@/js/constants.js';
import Request from '@/js/request.js';
import Utils from '@/js/utils.js';

const c = Constants.data();

export default {
  state: {
    navList: [],
    classMap: {},
    relationshipMap: {},
    attributeMap: {},
    databaseSummary: {},
  },
  mutations: {
    setDatabaseSummary(state, response_body) {
      state.databaseSummary = response_body;
    },
    setClassMap(state, response_body) {
      let classMap = {};
      (response_body ?? []).forEach(element => {
        // Sort Attribute and Relationships
        element[c.PPT_ATTRIBUTES].sort(function(a, b) {
          return a.order - b.order;
        });
        element[c.PPT_RELATIONSHIPS].sort(function(a, b) {
          return a.order - b.order;
        });
        classMap[element[c.PPT_NAME]] = element;
      });
      state.classMap = classMap;
      this.commit('refreshClass');
    },
    deleteClassInMap(state, class_name) {
      delete state.classMap[class_name];
      this.commit('refreshClass');
    },
    refreshClass() {
      this.commit('refreshNavList');
      this.commit('refreshAttributeList');
      this.commit('refreshRelationshipList');
    },
    refreshNavList(state) {
      let navList = [];
      for (let key in state.classMap) {
        navList.push(key);
      }
      navList.sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1));
      state.navList = navList;
    },
    refreshAttributeList(state) {
      let attributeMap = {};
      for (let key in state.classMap) {
        let attributes = state.classMap[key][c.PPT_ATTRIBUTES];
        attributes.forEach(attribute => {
          const attributeName = attribute[c.PPT_NAME];
          if (!attributeMap.hasOwnProperty(attributeName)) {
            attributeMap[attributeName] = attribute;
          }
        });
      }
      state.attributeMap = attributeMap;
    },
    refreshRelationshipList(state) {
      let relationshipMap = {};
      for (let key in state.classMap) {
        let relationships = state.classMap[key][c.PPT_RELATIONSHIPS];
        relationships.forEach(relationship => {
          const relationshipName = relationship[c.PPT_NAME];
          const className = relationship[c.PPT_TARGET_CLASS];
          if (!relationshipMap.hasOwnProperty(relationshipName)) {
            relationshipMap[relationshipName] = {
              [c.PPT_DESCRIPTION]: relationship[c.PPT_DESCRIPTION],
              [c.PPT_LABEL]: new Set(),
            };
          }
          relationshipMap[relationshipName][c.PPT_LABEL].add(className);
        });
      }
      state.relationshipMap = relationshipMap;
    },
  },
  actions: {
    async getDatabaseSummary({ state, commit }, refresh = false) {
      if (Object.keys(state.databaseSummary).length > 0 && !refresh) return;
      const response_body = await Request.get('/schema/overview', {}, refresh);
      commit('setDatabaseSummary', response_body);
    },
    async getAllClasses({ state, commit }, refresh = false) {
      if (Object.keys(state.classMap).length > 0 && !refresh) return;
      const response_body = await Request.get('/schema', {}, refresh);
      commit('setClassMap', response_body);
    },
    async addClass({ dispatch }, args) {
      const classObject = args.classObject;
      const onSuccessFunc = args.onSuccessFunc;
      const response_body = await Request.post('/schema', classObject);
      if (response_body !== undefined) {
        Utils.methods.notifySuccess('Class Added.');
        await dispatch('getAllClasses', true);
        if (onSuccessFunc) await onSuccessFunc();
      }
    },
    async updateClass({ dispatch }, args) {
      const classObject = args.classObject;
      const onSuccessFunc = args.onSuccessFunc;
      let className = classObject[c.PPT_NAME];
      const response_body = await Request.put(
        `/schema/${className}`,
        classObject,
      );
      if (response_body !== undefined) {
        Utils.methods.notifySuccess('Class Updated.');
        await dispatch('getAllClasses', true);
        if (onSuccessFunc) await onSuccessFunc();
      }
    },
    async deleteClass({ commit }, args) {
      const className = args.className;
      const onSuccessFunc = args.onSuccessFunc;
      const response_body = await Request.delete(`/schema/${className}`);
      if (response_body !== undefined) {
        Utils.methods.notifySuccess('Class Deleted.');
        commit('deleteClassInMap', className);
        if (onSuccessFunc) await onSuccessFunc();
      }
    },
  },
};
