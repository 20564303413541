<template>
  <app-masthead :showSearchField="false">
    <mds-layout-grid>
      <mds-row
        class="search-row"
        align-horizontal="center"
        align-vertical="center"
      >
        <mds-col class="max-width">
          <TermSearchField placeholder="Search and create new..." />
          <mds-button
            class="float-right"
            variation="flat"
            size="small"
            text="Advanced Search"
            @click="routeToAdvancedSearch"
          >
          </mds-button>
        </mds-col>
      </mds-row>
    </mds-layout-grid>
  </app-masthead>
</template>

<script>
import { MdsButton } from '@mds/button';
import { MdsCol, MdsLayoutGrid, MdsRow } from '@mds/layout-grid';
import AppMasthead from '@/components/elements/AppMasthead.vue';
import TermSearchField from '@/components/TermManagement/TermSearchField.vue';
import Utils from '@/js/utils.js';

export default {
  name: 'Home',
  components: {
    MdsButton,
    MdsCol,
    MdsLayoutGrid,
    MdsRow,
    AppMasthead,
    TermSearchField,
  },
  mixins: [Utils],
  methods: {
    routeToAdvancedSearch() {
      this.routeToPage('AdvancedSearch', {});
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/global.scss';
.max-width {
  max-width: 500px;
}
.search-row {
  height: 60vh;
  margin: 0;
}
</style>
