<template>
  <div class="filter-spacing">
    <!-- Label -->
    <div class="label-spacing">
      <span class="bold">{{ description }}</span>
      <mds-button
        class="float-right"
        variation="icon-only"
        icon="remove"
        size="small"
        type="button"
        @click="removeFilter"
      >
      </mds-button>
    </div>
    <!-- Relationship -->
    <div v-if="type === 'relationships'">
      <TermSearchField
        class="searchbox-spacing"
        size="small"
        :onSelectRoute="false"
        :labelFilter="label"
        @selected="selectedSearch"
      />
      <mds-checkbox
        class="label-spacing"
        v-if="name === 'isAbout'"
        v-model="searchAllNarrowerTerms"
      >
        Add all narrower terms
      </mds-checkbox>
      <mds-checkbox
        class="label-spacing"
        v-if="name === 'isAbout'"
        v-model="searchRelatedTerms"
      >
        Add related terms
      </mds-checkbox>
      <mds-tag-container v-for="(item, index) in searchList" :key="index">
        <mds-tag
          removable
          :on-light="true"
          @mds-tag-removed="tagRemoved(index)"
          >{{ item.name }}</mds-tag
        >
      </mds-tag-container>
      <mds-loader v-if="isSearchingNarrowerTerms"></mds-loader>
    </div>
    <!-- Attributes -->
    <div v-else-if="type === 'DateTime'">
      <AppDatePicker v-bind="startDate" @selected="selectedStartDate" />
      -
      <AppDatePicker v-bind="endDate" @selected="selectedEndDate" />
    </div>
    <mds-switch
      v-else-if="type === 'Bool'"
      v-model="switchValue"
      @change="switched"
      >{{ switchText }}</mds-switch
    >
    <mds-combo-box
      v-else-if="type === 'Free Text' && options !== undefined"
      hidden-label
      label="Hidden Label"
      multiple
      v-model="selectValue"
      :dataSet="options"
      @input="selectedComboBox"
    ></mds-combo-box>
    <mds-input
      v-else
      hidden-label
      label="Hidden Label"
      v-model="inputValue"
      @change="input"
    >
    </mds-input>
  </div>
</template>

<script>
import { MdsButton } from '@mds/button';
import AppDatePicker from '@/components/elements/AppDatePicker.vue';
import MdsCheckbox from '@mds/checkbox';
import MdsComboBox from '@mds/combo-box';
import MdsInput from '@mds/input';
import MdsLoader from '@mds/loader';
import MdsSwitch from '@mds/switch';
import { MdsTag, MdsTagContainer } from '@mds/tag';
import TermSearchField from '@/components/TermManagement/TermSearchField.vue';
import Constants from '@/js/constants.js';
import EventBus from '@/js/event-bus.js';
import Request from '@/js/request.js';
import Utils from '@/js/utils.js';

export default {
  name: 'AdvancedSearchFilter',
  components: {
    MdsButton,
    AppDatePicker,
    MdsCheckbox,
    MdsComboBox,
    MdsInput,
    MdsLoader,
    MdsSwitch,
    MdsTag,
    MdsTagContainer,
    TermSearchField,
  },
  props: {
    name: { type: String, required: true },
    description: { type: String, required: true },
    type: { type: String, required: true },
    label: { type: Set, required: false },
    value: { type: [String, Array, Object], required: false },
    options: { type: [Array], required: false },
  },
  mixins: [Constants, Utils],
  mounted() {
    if (this.type === 'relationships') {
      EventBus.$on('TermNameMapUpdated', this.updateRelationshipName);
    }
  },
  data() {
    return {
      inputValue: this.getInputValue(),
      selectValue: this.getSelectValue(),
      switchValue: this.getSwitchValue(),
      searchList: this.getSearchList(),
      startDate: this.getStartDate(),
      endDate: this.getEndDate(),
      searchAllNarrowerTerms: false,
      searchRelatedTerms: false,
      isSearchingNarrowerTerms: false,
    };
  },
  methods: {
    getInputValue() {
      return this.value ?? '';
    },
    getSelectValue() {
      if (this.type !== 'Free Text' || this.options === undefined) return null;
      let selectList = [];
      if (this.value && typeof this.value === 'string') {
        selectList = this.value.split(',');
      }
      return selectList;
    },
    getSwitchValue() {
      if (this.type !== 'Bool') return null;
      if (this.value) {
        return this.value === 'true';
      }
      return undefined;
    },
    getSearchList() {
      if (this.type !== 'relationships') return [];
      let searchList = [];
      const termNameMap = this.$store.state.term.termNameMap;
      if (this.value && typeof this.value === 'string') {
        const valueList = this.value.split(',');
        valueList.forEach(value => {
          if (termNameMap.hasOwnProperty(value)) {
            searchList.push(termNameMap[value]);
          }
        });
      }
      return searchList;
    },
    getStartDate() {
      if (this.type !== 'DateTime') return null;
      return {
        value:
          this.value && this.value.hasOwnProperty('gte')
            ? new Date(`${this.value['gte']}T00:00:00`)
            : null,
        placeholder: 'From',
      };
    },
    getEndDate() {
      if (this.type !== 'DateTime') return null;
      return {
        value:
          this.value && this.value.hasOwnProperty('lte')
            ? new Date(`${this.value['lte']}T00:00:00`)
            : null,
        placeholder: 'To',
      };
    },
    updateRelationshipName() {
      this.searchList = this.getSearchList();
    },
    removeFilter() {
      this.$emit('remove');
    },
    async selectedSearch(item) {
      this.pushSearchList(item);
      if (
        this.name === 'isAbout' &&
        item.label === 'concept' &&
        (this.searchAllNarrowerTerms || this.searchRelatedTerms)
      ) {
        let hierarchy_type = 'flat_related';
        if (!this.searchRelatedTerms) hierarchy_type = 'flat';
        if (!this.searchAllNarrowerTerms) hierarchy_type = 'related';
        this.isSearchingNarrowerTerms = true;
        const response_body = await Request.get('/hierarchy', {
          id: item.id,
          type: hierarchy_type,
        });
        this.isSearchingNarrowerTerms = false;
        if (response_body !== undefined) {
          response_body.forEach(element => {
            element.label = 'concept';
            this.pushSearchList(element);
          });
        }
      }
      this.updateList();
    },
    pushSearchList(item) {
      if (this.searchSet.has(item.id)) {
        this.notifyWarning(
          `${item.name} is in the search filter.`,
          'Duplicate Value Skipped',
        );
        return;
      }
      this.searchList.push(item);
    },
    selectedComboBox() {
      this.$emit(
        'update',
        this.selectValue.length === 0 ? null : this.selectValue,
      );
    },
    tagRemoved(index) {
      this.searchList.splice(index, 1);
      this.updateList();
    },
    updateList() {
      let selectedIds = {};
      this.searchList.forEach(element => {
        if (!selectedIds.hasOwnProperty(element.label)) {
          selectedIds[element.label] = [];
        }
        selectedIds[element.label].push(element.id);
      });
      this.$emit('update', this.searchList.length === 0 ? null : selectedIds);
    },
    input() {
      this.$emit('update', this.inputValue === '' ? null : this.inputValue);
    },
    selectedStartDate(date) {
      this.startDate.value = date;
      this.updateDate();
    },
    selectedEndDate(date) {
      this.endDate.value = date;
      this.updateDate();
    },
    updateDate() {
      let range = {};
      if (
        this.startDate.value &&
        this.endDate.value &&
        this.startDate.value > this.endDate.value
      ) {
        this.$set(this.startDate, 'error', true);
        this.$set(this.endDate, 'error', true);
        this.$set(this.endDate, 'errorText', [
          'The start date should be earlier than the end date.',
        ]);
      } else {
        this.$set(this.startDate, 'error', false);
        this.$set(this.endDate, 'error', false);
      }
      if (this.startDate.value)
        range['gte'] = this.formatDate(this.startDate.value);
      if (this.endDate.value)
        range['lte'] = this.formatDate(this.endDate.value);
      this.$emit('update', range);
    },
    switched(newValue) {
      this.$emit('update', newValue ? 'true' : 'false');
    },
  },
  computed: {
    switchText() {
      if (this.switchValue === undefined) return 'Not selected';
      return this.switchValue ? 'True' : 'False';
    },
    searchSet() {
      let searchSet = new Set();
      this.searchList.forEach(element => {
        searchSet.add(element.id);
      });
      return searchSet;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/global.scss';
.label-spacing {
  margin: #{$mds-space-half-x} 0 #{$mds-space-half-x} 0;
}
.filter-spacing {
  margin: 0 0 #{$mds-space-2-and-a-half-x} 0;
}
.searchbox-spacing {
  margin: 0 0 #{$mds-space-half-x} 0;
}
</style>
