<template>
  <mds-label
    class="inline-text"
    :size="size"
    :required="required"
    :required-text="requiredText"
    :text="label"
    :hidden-label="hiddenLabel"
    @click.prevent.native="setFocus"
    v-click-outside="unFocus"
  >
    <mds-button
      v-if="tooltip !== ''"
      id="tooltip-trigger"
      variation="icon-only"
      icon="info-circle"
      size="small"
      @mouseover.native="tooltipToggle = true"
      @mouseleave.native="tooltipToggle = false"
      @focus.native="tooltipToggle = true"
    >
    </mds-button>
    <mds-tooltip
      v-if="tooltip !== ''"
      triggered-by="tooltip-trigger"
      v-model="tooltipToggle"
      position="right-center"
    >
      {{ tooltip }}
    </mds-tooltip>
    <div
      :disabled="disabled"
      :class="[$style['mds-combo-box'], classObject, sizeClass]"
    >
      <div
        :class="$style['mds-combo-box__multiple-select']"
        :tabindex="this.disabled ? -1 : 0"
      >
        <div :class="[$style['mds-combo-box__selected-list'], focusClass]">
          <span>
            <mds-tag
              :class="$style['mds-combo-box__selection-tag']"
              v-for="(item, index) in selectedItems"
              :key="index"
              :text="item"
              :on-light="true"
              removable
              :size="tagSize"
              @mds-tag-removed="deleteTags(index)"
            >
            </mds-tag>
          </span>
          <span :class="$style['mds-combo-box__flex-container']">
            <input
              type="text"
              v-model="newTerm"
              :style="styleObject"
              :class="$style['mds-combo-box__input']"
              :disabled="disabled"
              :tabindex="this.disabled ? -1 : 0"
              :placeholder="placeholder"
              @keydown.enter.prevent="addTag"
              @keydown.tab="handleTab"
              ref="input"
            />
          </span>
        </div>
      </div>
    </div>
    <div v-if="error" class="mds-input__field-error-wrapper-dp">
      <span
        class="mds-field-error"
        v-for="(text, index) in errorText"
        :key="index"
      >
        {{ text }}
      </span>
    </div>
  </mds-label>
</template>

<script>
import { MdsButton } from '@mds/button';
import MdsLabel from '@mds/label';
import { MdsTag } from '@mds/tag';
import MdsTooltip from '@mds/tooltip';
import '@/js/directive.js';

export default {
  name: 'AppInputTags',
  components: {
    MdsButton,
    MdsLabel,
    MdsTag,
    MdsTooltip,
  },
  props: {
    label: { type: String, required: true },
    hiddenLabel: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    errorText: { type: Array, default: () => [] },
    //TODO: placeholder is not complete after update
    placeholder: { type: String, default: 'Type and press Enter to add' },
    required: { type: Boolean, default: false },
    requiredText: { type: String, default: 'This field is required' },
    tooltip: { type: String, default: '' },
    size: {
      type: String,
      default: 'medium',
      validator(value) {
        return ['small', 'medium', 'large', 'touch'].indexOf(value) !== -1;
      },
      docs: {
        validation: 'One of: `small`, `medium`, `large`, `touch`.',
        description: 'Sets the size of the combo box.',
      },
    },
    value: { type: Array, default: () => [] },
  },
  data() {
    return {
      isFocused: false,
      tooltipToggle: false,
      newTerm: '',
      selectedItems: this.value,
    };
  },
  computed: {
    sizeClass() {
      return this.$style[`mds-combo-box--${this.size}`];
    },
    tagSize() {
      return this.size === 'large' || this.size === 'touch'
        ? 'medium'
        : 'small';
    },
    focusClass() {
      const obj = {};
      obj[this.$style['mds-combo-box--focus']] = this.isFocused;
      return obj;
    },
    styleObject() {
      return {
        width: `${this.newTerm.length / 2}em`,
      };
    },
    classObject() {
      const obj = {};
      obj[this.$style['mds-combo-box--error']] = this.error;
      return obj;
    },
  },
  methods: {
    addTag() {
      if (this.newTerm === '') return;
      if (!this.selectedItems.includes(this.newTerm)) {
        this.selectedItems.push(this.newTerm);
        this.emitChange();
      }
      this.newTerm = '';
    },
    deleteTags(index) {
      if (typeof index !== 'number') index = this.selectedItems.length - 1;
      this.selectedItems.splice(index, 1);
      this.emitChange();
    },
    unFocus() {
      if (!this.disabled && this.isFocused) {
        this.isFocused = false;
      }
    },
    setFocus() {
      if (!this.disabled && !this.isFocused) {
        this.isFocused = true;
        this.$refs.input.focus();
      }
    },
    handleTab() {
      this.unFocus();
    },
    emitChange() {
      this.$emit('change', this.selectedItems);
    },
  },
  watch: {
    value(newValue) {
      this.selectedItems = newValue;
    },
  },
};
</script>

<style lang="scss" module>
@import 'node_modules/@mds/combo-box/src/combo-box.scss';
@import '@/style/global.scss';
.mds-input__field-error-wrapper-dp {
  margin-top: 4px;
}
</style>

<style scoped>
#tooltip-trigger {
  margin-left: 4px;
}
</style>
