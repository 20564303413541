<template>
  <app-masthead>
    <mds-loader v-if="isLoading || isLoadingAuth"></mds-loader>
    <div v-else-if="!hasAccess">Sorry, you don't have write access.</div>
    <div v-else>
      <div v-if="!isCreateEntry && !isTermExist">
        Term {{ activeTerm }} does not exist.
      </div>
      <div v-else>
        <mds-form onsubmit="return false">
          <div class="edit-term-form-area">
            <mds-select
              name="taxonomyClass"
              v-model="termObject['label']"
              label="Primary Classification (Class)"
              :options="classOptions"
              @change="classSelected"
              :disabled="!isCreateEntry"
            ></mds-select>

            <mds-tabs size="large" class="edit-term-tab">
              <mds-tabs-item
                v-for="(item, index) in tabsContent"
                :key="index"
                :active="item.active"
                :id="item.id"
                :text="item.text"
                :target="item.target"
                @click.native="setActiveItem"
              >
              </mds-tabs-item>
            </mds-tabs>
            <div id="tabEditRelationship" class="tab-placeholders">
              <TermEditRelationship
                v-for="relationship in relationshipSchema"
                :key="`${relationship.name}${relationship.targetClass}`"
                v-bind="relationship"
                @change="relationshipChanged(relationship, $event)"
                ref="relationships"
              />
            </div>
            <div
              id="tabEditAttribute"
              style="display:none"
              class="tab-placeholders"
            >
              <TermEditAttribute
                v-for="(attribute, attributeIdx) in attributeSchema"
                v-bind="attribute"
                :key="`${attribute.name}${attributeIdx}`"
                @change="attributeChanged(attribute.name, $event)"
                ref="attributes"
              />
            </div>
          </div>
          <div class="edit-term-button-area">
            <!-- Action Buttons -->
            <mds-button-container>
              <mds-button
                variation="primary"
                type="button"
                :disabled="!isDirty || isSubmiting || isDeleting"
                :loading="isSubmiting"
                @click="submit"
              >
                Submit
              </mds-button>
              <mds-button
                class="dangerous-button"
                v-if="!isCreateEntry"
                variation="primary"
                type="button"
                :disabled="isSubmiting || isDeleting"
                :loading="isDeleting"
                @click="toggleDelete = true"
              >
                Delete Entry
              </mds-button>
              <mds-button
                :disabled="isSubmiting || isDeleting"
                @click="cancelEditing"
              >
                Cancel
              </mds-button>
            </mds-button-container>
          </div>
        </mds-form>
      </div>
    </div>
    <mds-modal title="Warning" v-model="toggleDelete" action-required>
      Are you sure you want to permanently delete this entry?
      <div v-if="hasRelationship">
        All relationships of this entry will be deleted too!
      </div>
      <template v-slot:mds-modal-actions>
        <mds-button-container right-aligned>
          <mds-button @click="toggleDelete = false" variation="secondary">
            No, cancel
          </mds-button>
          <mds-button @click="deleteTerm" variation="primary" type="button">
            Yes, continue
          </mds-button>
        </mds-button-container>
      </template>
    </mds-modal>
  </app-masthead>
</template>

<script>
import Auth from '@/js/auth.js';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import AppMasthead from '@/components/elements/AppMasthead.vue';
import Constants from '@/js/constants.js';
import MdsForm from '@mds/form';
import MdsLoader from '@mds/loader';
import MdsModal from '@mds/modal';
import MdsSelect from '@mds/select';
import { MdsTabs, MdsTabsItem } from '@mds/tabs';
import TermEditAttribute from './TermEditAttribute.vue';
import TermEditRelationship from './TermEditRelationship.vue';
import UnsavedChanges from '@/js/unsaved-changes.js';
import Utils from '@/js/utils.js';
import moment from 'moment';

export default {
  name: 'TermEdit',
  components: {
    AppMasthead,
    MdsButton,
    MdsButtonContainer,
    MdsForm,
    MdsLoader,
    MdsModal,
    MdsSelect,
    MdsTabs,
    MdsTabsItem,
    TermEditAttribute,
    TermEditRelationship,
  },
  mixins: [Auth, Constants, UnsavedChanges, Utils],
  mounted: async function() {
    //TODO: called isAuthenticate twice
    this.isLoadingAuth = true;
    await this.isAuthenticated();
    this.isLoadingAuth = false;
    if (this.hasAccess) {
      await this.load();
      window.addEventListener('beforeunload', this.onLeavePage);
    }
  },
  destroyed() {
    window.removeEventListener('beforeunload', this.onLeavePage);
  },
  data() {
    return {
      termObject: this.initClass(''),
      isLoading: false,
      isLoadingAuth: false,
      isDirtyAttribute: false,
      isDirtyRelationship: false,
      isSubmiting: false,
      isDeleting: false,
      classOptions: [],
      termSchema: [],
      attributeSchema: [],
      relationshipSchema: [],
      toggleDelete: false,
      tabsContent: [
        {
          text: 'Relationships',
          id: 'relationships',
          active: true,
          target: 'tabEditRelationship',
        },
        {
          text: 'Details',
          id: 'details',
          active: false,
          target: 'tabEditAttribute',
        },
      ],
    };
  },
  methods: {
    setActiveItem(event) {
      this.tabsContent.forEach(item => {
        let placeHolder = document.getElementById(item.target);

        if (item.id === event.currentTarget.id) {
          item.active = true;
          placeHolder.style.display = 'block';
        } else {
          item.active = false;
          placeHolder.style.display = 'none';
        }
      });
    },
    async load() {
      this.isLoading = true;
      if (this.isCreateEntry) {
        await this.loadClassOptions();
      } else {
        await this.loadTerm();
      }
      this.isLoading = false;
    },
    async loadTerm() {
      if (this.isCreateEntry) {
        this.termObject = {};
        this.attributeSchema = [];
        this.relationshipSchema = [];
        return;
      }
      await this.$store.dispatch('getTermById', {
        termId: this.activeTerm,
        refresh: true,
      });
      this.termObject = this.deepCopy(this.$store.state.term.termObject);
      if (!this.termObject) return;
      document.title = `${
        this.termObject[this.PPT_ATTRIBUTES][this.PPT_NAME]
      } | Edit Term | ${process.env.VUE_APP_TITLE}`;
      this.loadSchema();
    },
    loadSchema(className = null) {
      this.termSchema = this.isCreateEntry
        ? this.$store.state.schema.classMap[className]
        : this.$store.state.term.termSchema;
      let attributeSchema = this.termSchema[this.PPT_ATTRIBUTES];
      let relationshipSchema = this.termSchema[this.PPT_RELATIONSHIPS];
      // assign values to attributes and relationships
      attributeSchema.forEach(attribute => {
        if (
          !this.isCreateEntry &&
          this.termObject['attributes'].hasOwnProperty(attribute['name'])
        ) {
          attribute['value'] = this.termObject['attributes'][attribute['name']];
        }
        if (this.isCreateEntry && attribute['name'] === 'dataSource') {
          attribute['value'] = 'Taxonomy';
        }
        if (this.isCreateEntry && attribute['name'] === 'updateDate') {
          let today = moment(new Date()).format('YYYY-MM-DD');
          attribute['value'] = today;
        }
      });
      if (!this.isCreateEntry) {
        relationshipSchema.forEach(relationship => {
          relationship['id'] = this.termObject['id'];
          const name = relationship['name'];
          const targetClass = relationship['targetClass'];
          if (
            this.termObject['relationships'].hasOwnProperty(name) &&
            this.termObject['relationships'][name].hasOwnProperty(targetClass)
          ) {
            relationship['value'] = this.termObject['relationships'][name][
              targetClass
            ];
            relationship['count'] = this.termObject['relationships_number'][
              name
            ][targetClass];
          }
        });
      }
      this.attributeSchema = [];
      this.relationshipSchema = [];
      setTimeout(() => {
        this.attributeSchema = attributeSchema;
        this.relationshipSchema = relationshipSchema;
      }, 1);
    },
    async loadClassOptions() {
      await this.$store.dispatch('getAllClasses');
      let classOptions = [];
      for (let key in this.$store.state.schema.classMap) {
        if (
          this.$store.state.schema.classMap[key][this.PPT_DATA_SOURCE].includes(
            'Taxonomy',
          )
        ) {
          classOptions.push({
            value: key,
            text: this.decamelize(key),
          });
        }
      }
      this.classOptions = classOptions;
    },
    classSelected(className) {
      this.isDirtyAttribute = true;
      this.loadSchema(className);
      this.termObject = this.initClass(className);
    },
    initClass(className) {
      return {
        label: className,
        attributes: { dataSource: 'Taxonomy' },
        relationships: {},
        relationships_add: {},
        relationships_delete: {},
      };
    },
    cancelEditing() {
      if (!this.confirmStayInDirtyForm(this.isDirty)) {
        if (this.isCreateEntry) {
          this.routeToPage('Home');
        } else {
          this.routeToPage('TermView', {}, this.$route.params);
        }
      }
    },
    attributeChanged(attributeName, newValue) {
      if (!this.termObject.hasOwnProperty(this.PPT_ATTRIBUTES))
        this.termObject[this.PPT_ATTRIBUTES] = {};
      this.termObject[this.PPT_ATTRIBUTES][attributeName] = newValue;
      this.isDirtyAttribute = true;
    },
    relationshipChanged(relationship, newValue) {
      const name = relationship.name;
      const targetClass = relationship.targetClass;
      if (Object.keys(newValue['relationships_add']).length > 0) {
        if (!this.termObject.hasOwnProperty('relationships_add'))
          this.termObject['relationships_add'] = {};
        if (!this.termObject['relationships_add'].hasOwnProperty(name))
          this.termObject['relationships_add'][name] = {};
        this.termObject['relationships_add'][name][targetClass] =
          newValue['relationships_add'];
      }
      if (Object.keys(newValue['relationships_delete']).length > 0) {
        if (!this.termObject.hasOwnProperty('relationships_delete'))
          this.termObject['relationships_delete'] = {};
        if (!this.termObject['relationships_delete'].hasOwnProperty(name))
          this.termObject['relationships_delete'][name] = {};
        this.termObject['relationships_delete'][name][targetClass] =
          newValue['relationships_delete'];
      }
      this.isDirtyRelationship = true;
    },
    async submit() {
      this.isSubmiting = true;
      const validated = await this.validate();
      if (!validated) {
        this.isSubmiting = false;
        return;
      }

      const actionName = this.isCreateEntry ? 'addEntry' : 'updateEntry';
      let termObject = this.termObject;
      if (!this.isCreateEntry && !this.isDirtyAttribute) {
        if (termObject) {
          termObject = this.deepCopy(this.termObject);
          delete termObject['attributes'];
        }
      }
      this.$store.dispatch('setSchema', this.termSchema);
      await this.$store.dispatch(actionName, termObject);

      this.isSubmiting = false;
      if (this.$store.state.term.termObject) {
        this.routeToPage(
          'TermView',
          {},
          { entryId: this.$store.state.term.termObject['id'] },
        );
      }
    },
    async validate() {
      let result = '';
      for (let index = 0; index < this.attributeSchema.length; index++) {
        let validateString = await this.$refs.attributes[index].validate();
        result = result + validateString;
      }
      for (let index = 0; index < this.relationshipSchema.length; index++) {
        let validateString = await this.$refs.relationships[index].validate();
        result = result + validateString;
      }
      if (result !== '') {
        this.notifyError(
          `Please review ${result}then try again.`,
          'Validation failed',
        );
        return false;
      }
      return true;
    },
    async deleteTerm() {
      this.toggleDelete = false;
      this.isDeleting = true;
      await this.$store.dispatch('deleteEntry', this.activeTerm);
      this.isDeleting = false;
      if (!this.$store.state.term.termObject) {
        this.routeToPage('Home');
      }
    },
    onLeavePage(e) {
      this.beforeWindowUnload(e, this.isDirty);
    },
  },
  computed: {
    hasAccess() {
      return this.userGroup === 'admin' || this.userGroup === 'writer';
    },
    isDirty() {
      return this.isDirtyAttribute || this.isDirtyRelationship;
    },
    isTermExist() {
      return this.termObject !== null;
    },
    isCreateEntry() {
      return this.$route.name === 'TermCreate';
    },
    activeTerm() {
      return this.$route.params.entryId;
    },
    hasRelationship() {
      return (
        this.termObject.hasOwnProperty('relationships') &&
        Object.keys(this.termObject['relationships']).length > 0
      );
    },
  },
  watch: {
    async activeTerm() {
      await this.load();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/global.scss';
.edit-term-tab {
  margin-left: #{$mds-space-1-x};
  margin-bottom: #{$mds-space-3-x};
}
.scr-trigger-margin {
  margin-bottom: #{$mds-space-2-x};
}
.edit-term-form-area {
  height: calc(90vh - 20px);
  overflow-y: auto;
  overflow-x: hidden;
}
.edit-term-button-area {
  padding-top: #{$mds-space-2-x};
  padding-bottom: #{$mds-space-1-x};
}
.tab-placeholders {
  position: relative;
  margin-left: #{$mds-space-1-x};
  margin-right: #{$mds-space-1-x};
}
</style>
