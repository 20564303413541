<template>
  <div>
    <label class="mds-label table-label">
      <div class="mds-label__text table-label-text">
        {{ label }}
      </div>
    </label>
    <AppIconTooltip
      v-if="isEditing"
      icon="plus"
      tooltip="add a row"
      @click="addRow"
    />
    <mds-data-table
      v-if="rows_.length > 0"
      class="table-margin table-cell-wrap"
      row-hover
      :header-configs="headers_"
      :row-data="rows_"
      :id="label"
    >
      <template v-slot:body-cell="cellData">
        <mds-button
          v-if="cellData.headerConfig.fieldName.includes('ActionButton')"
          class="table-button"
          variation="icon-only"
          :icon="cellData.rowData[cellData.headerConfig.fieldName]"
          @click="
            actionButtonClicked(cellData.headerConfig.fieldName, cellData)
          "
        >
        </mds-button>
        <span
          v-else-if="
            typeof cellData.rowData[cellData.headerConfig.fieldName] ===
              'boolean'
          "
        >
          <span v-if="cellData.rowData[cellData.headerConfig.fieldName]">
            √
          </span>
        </span>
      </template>
    </mds-data-table>
    <SchemaAttribute
      :toggle="label === 'Attributes' && toggleEdit"
      :isNew="isNewLine"
      :value="curLine"
      @cancel="cancelEdit"
      @save="saveEdit"
    />
    <SchemaRelationship
      :toggle="label !== 'Attributes' && toggleEdit"
      :isNew="isNewLine"
      :value="curLine"
      @cancel="cancelEdit"
      @save="saveEdit"
    />
  </div>
</template>

<script>
import AppIconTooltip from '@/components/elements/AppIconTooltip.vue';
import { MdsButton } from '@mds/button';
import { MdsDataTable } from '@mds/data-table';
import SchemaAttribute from './SchemaAttribute.vue';
import SchemaRelationship from './SchemaRelationship.vue';
import $ from 'jquery';
import 'jquery-ui/ui/widgets/sortable';
import Constants from '@/js/constants.js';
import Utils from '@/js/utils.js';

export default {
  name: 'SchemaDataTable',
  components: {
    AppIconTooltip,
    MdsButton,
    MdsDataTable,
    SchemaAttribute,
    SchemaRelationship,
  },
  props: {
    isEditing: Boolean,
    label: String,
    rows: Array,
    headers: Array,
  },
  mixins: [Constants, Utils],
  mounted() {
    this.initId();
    this.refreshPage();
  },
  data() {
    return {
      headers_: this.headers,
      rows_: this.rows,
      toggleEdit: false,
      isNewLine: false,
      curLine: {},
      curLineIdx: -1,
    };
  },
  methods: {
    refreshPage() {
      if (this.rows_.length > 0 && this.isEditing) {
        this.initSortable();
        this.insertActionButtons();
      }
    },
    initSortable() {
      let this_ = this;
      $(`#${this.label} tbody`).sortable({
        update: () => {
          this_.emitChange();
        },
      });
      $(`#${this.label} tbody`).sortable('enable');
    },
    getRowKey(row) {
      if (this.label === 'Relationships') {
        return `${row['name']}&${row['targetClass']}`;
      }
      return row['name'];
    },
    assignSortableId(index) {
      $(`#${this.label} tr`)[index + 1].id = `${this.label}-${this.getRowKey(
        this.rows_[index],
      )}`;
    },
    initId() {
      for (let index = 0; index < this.rows_.length; index++) {
        this.assignSortableId(index);
      }
    },
    actionButtonClicked(action, cellData) {
      if (!this.SCHEMA_UNEDITABLE_FIELDS.includes(cellData.rowData.id)) {
        if (action === 'editActionButton') {
          this.editRow(cellData);
        } else if (action === 'removeActionButton') {
          this.removeRow(cellData);
        }
      } else {
        this.notifyWarning(`${cellData.rowData.id} is an Uneditable Field.`);
      }
    },
    insertActionButtons() {
      this.headers_ = this.headers.concat([
        {
          fieldName: 'editActionButton',
          text: ' ',
          width: '30px',
        },
        {
          fieldName: 'removeActionButton',
          text: ' ',
          width: '30px',
        },
      ]);
      let rows_ = [];
      this.rows.forEach(element => {
        let row = {};
        if (!this.SCHEMA_UNEDITABLE_FIELDS.includes(element.id)) {
          row = {
            editActionButton: 'pencil',
            removeActionButton: 'remove',
          };
        }
        Object.assign(row, element);
        rows_.push(row);
      });
      this.rows_ = rows_;
    },
    addRow() {
      this.curLine = {};
      this.curLineIdx = -1;
      this.isNewLine = true;
      this.toggleEdit = true;
      this.emitChange();
    },
    removeRow(cellData) {
      this.rows_.splice(cellData.rowIndex, 1);
      this.initId();
      this.emitChange();
    },
    editRow(cellData) {
      this.curLine = this.rows_[cellData.rowIndex];
      this.curLineIdx = cellData.rowIndex;
      this.isNewLine = false;
      this.toggleEdit = true;
    },
    cancelEdit() {
      this.toggleEdit = false;
    },
    saveEdit(newValue) {
      this.cancelEdit();
      if (this.curLineIdx < 0) {
        // Add new line
        newValue['id'] = this.rows_.length;
        newValue['editActionButton'] = 'pencil';
        newValue['removeActionButton'] = 'remove';
        this.rows_.push(newValue);
        setTimeout(() => {
          if (this.rows_.length === 1) this.initSortable();
          this.assignSortableId(this.rows_.length - 1);
        }, 1);
      } else {
        this.$set(this.rows_, this.curLineIdx, newValue);
      }
      this.toggleEdit = false;
      this.emitChange();
    },
    emitChange() {
      this.$emit('change');
    },
    validate() {
      let duplicate = new Set(),
        nameSet = new Set(),
        orderList = [],
        orderMap = {};
      if (this.rows_.length > 0) {
        // Build a mapping for order
        const order = $(`#${this.label} tbody`).sortable('toArray');
        for (let index = 0; index < order.length; index++) {
          const rowId = order[index];
          let rowIdList = rowId.split('-');
          orderMap[rowIdList[1]] = index;
        }
      }
      this.rows_.forEach(element => {
        const name = this.getRowKey(element);
        element['order'] = orderMap[this.getRowKey(element)];
        orderList.push({ order: element['order'], name: element['name'] });
        if (nameSet.has(name)) {
          duplicate.add(name);
        } else {
          nameSet.add(name);
        }
      });
      if (duplicate.size > 0) {
        this.notifyError(
          `Cannot have more than one ${this.label} with name: ${Array.from(
            duplicate,
          ).join(', ')}.`,
        );
        return false;
      }
      if (this.label === 'Attributes' && !nameSet.has('name')) {
        this.notifyWarning(
          'Attribute [Name] is not defined. You might not be able to search this class.',
          'Missing Important Attributes',
        );
      }
      if (this.label === 'Relationships') {
        orderList.sort(function(a, b) {
          return a.order - b.order;
        });
        nameSet = new Set();
        for (let index = 0; index < orderList.length; index++) {
          const name = orderList[index]['name'];
          if (nameSet.has(name)) {
            if (orderList[index - 1]['name'] !== name) {
              this.notifyWarning(
                `Relationships ${name} is not grouped together.`,
                'Undesired Relationships Order',
              );
            }
          } else {
            nameSet.add(name);
          }
        }
      }
      return true;
    },
    submit() {
      let result = [];
      this.rows_.forEach(element => {
        let element_ = this.deepCopy(element);
        delete element_['editActionButton'];
        delete element_['removeActionButton'];
        delete element_['id'];
        result.push(element_);
      });
      return result;
    },
  },
  watch: {
    rows(newValue) {
      this.rows_ = newValue;
      this.refreshPage();
    },
    isEditing() {
      this.refreshPage();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/global.scss';
.table-margin {
  margin-bottom: $mds-space-2-x;
}
.table-label {
  display: inline;
  vertical-align: top;
}
.table-label-text {
  display: inline-flex;
  margin: $mds-space-quarter-x $mds-space-1-x $mds-space-1-x 0;
}
</style>
