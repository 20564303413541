<template>
  <app-masthead>
    <template v-slot:mds-page-shell-vertical-nav>
      <SchemaNav :isLoading="isLoading" />
    </template>
    <div v-if="showSummary">
      <SchemaSummary :classMap="classMap" />
      <SchemaGraph :classMap="classMap" />
    </div>
    <div v-else>
      <mds-loader v-if="isLoading || isLoadingAuth"></mds-loader>
      <div v-else-if="!hasAccess">Sorry, you don't have admin access.</div>
      <div v-else-if="!isClassExist">
        Class {{ activeClass }} does not exist.
      </div>
      <mds-section
        :title="
          isCreateClass ? 'Create A New Class' : decamelize(className.value)
        "
        bold
        border="none"
        v-else
      >
        <template v-slot:mds-section-title-description>
          <AppIconTooltip
            v-if="!isEditing"
            icon="pencil"
            tooltip="edit class"
            @click="startEditing"
          />
        </template>
        <mds-form onsubmit="return false">
          <mds-input
            v-if="isCreateClass"
            v-bind="className"
            v-model="className.value"
            @change="markDirty"
          ></mds-input>
          <mds-combo-box
            label="Data Source"
            v-if="isEditing"
            multiple
            v-model="dataSource"
            :dataSet="dataSourceOptions"
            @input="markDirty"
          ></mds-combo-box>
          <div v-else>Data Source: {{ dataSource.join(', ') }}</div>
          <!-- Attributes -->
          <SchemaDataTable
            :headers="attributeHeader"
            :rows="attributeRows"
            :isEditing="isEditing"
            :label="'Attributes'"
            ref="Attributes"
            :key="`Attributes${componentKey}`"
            @change="markDirty"
          />
          <!-- Relationships -->
          <SchemaDataTable
            :headers="relationshipHeader"
            :rows="relationshipRows"
            :isEditing="isEditing"
            :label="'Relationships'"
            ref="Relationships"
            :key="`Relationships${componentKey}`"
            @change="markDirty"
          />
          <!-- Action Buttons -->
          <mds-button-container>
            <mds-button
              v-if="isEditing"
              variation="primary"
              type="button"
              :disabled="!isDirty || isSubmiting || isDeleting"
              :loading="isSubmiting"
              @click="submit"
            >
              Submit
            </mds-button>
            <mds-button
              v-if="isEditing"
              class="dangerous-button"
              variation="primary"
              type="button"
              :disabled="isSubmiting || isDeleting"
              :loading="isDeleting"
              @click="toggleDelete = true"
            >
              Delete Class
            </mds-button>
            <mds-button
              v-if="isEditing"
              :disabled="isSubmiting || isDeleting"
              @click="cancelEditing"
            >
              Cancel
            </mds-button>
          </mds-button-container>
          <!-- Modals -->
          <mds-modal
            title="Warning"
            v-model="toggleDelete"
            :width="'600px'"
            action-required
          >
            Are you sure you want to permanently delete this class?
            <template v-slot:mds-modal-actions>
              <mds-button-container right-aligned>
                <mds-button
                  @click="toggleDelete = !toggleDelete"
                  variation="secondary"
                >
                  No, cancel
                </mds-button>
                <mds-button @click="deleteClass" variation="primary">
                  Yes, continue
                </mds-button>
              </mds-button-container>
            </template>
          </mds-modal>
        </mds-form>
      </mds-section>
    </div>
  </app-masthead>
</template>

<script>
import Auth from '@/js/auth.js';
import AppIconTooltip from '@/components/elements/AppIconTooltip.vue';
import AppMasthead from '@/components/elements/AppMasthead.vue';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import Constants from '@/js/constants.js';
import MdsComboBox from '@mds/combo-box';
import MdsForm from '@mds/form';
import MdsInput from '@mds/input';
import MdsLoader from '@mds/loader';
import MdsModal from '@mds/modal';
import MdsSection from '@mds/section';
import SchemaDataTable from './SchemaDataTable.vue';
import SchemaGraph from './SchemaGraph.vue';
import SchemaNav from './SchemaNav.vue';
import SchemaSummary from './SchemaSummary.vue';
import Request from '@/js/request.js';
import UnsavedChanges from '@/js/unsaved-changes.js';
import Utils from '@/js/utils.js';

export default {
  name: 'SchemaManagement',
  components: {
    AppIconTooltip,
    AppMasthead,
    MdsButtonContainer,
    MdsButton,
    MdsComboBox,
    MdsForm,
    MdsModal,
    MdsInput,
    MdsLoader,
    MdsSection,
    SchemaDataTable,
    SchemaGraph,
    SchemaNav,
    SchemaSummary,
  },
  mixins: [Auth, Constants, UnsavedChanges, Utils],
  data() {
    return {
      componentKey: 0,
      isLoading: false,
      isLoadingAuth: false,
      isDirty: false,
      isClassExist: true,
      isSubmiting: false,
      isDeleting: false,
      toggleDelete: false,
      classObj: {},
      classMap: {},
      attributeHeader: [
        {
          fieldName: 'name',
          text: 'Name',
        },
        {
          fieldName: 'description',
          text: 'Description',
        },
        {
          fieldName: 'type',
          text: 'Type',
        },
        {
          fieldName: 'required',
          text: 'Required',
          align: 'right',
        },
        {
          fieldName: 'singleValue',
          text: 'Single Value',
          align: 'right',
        },
        {
          fieldName: 'readOnly',
          text: 'Read Only',
          align: 'right',
        },
        {
          fieldName: 'predefinedList',
          text: 'Predefined List',
        },
      ],
      relationshipHeader: [
        {
          fieldName: 'name',
          text: 'Name',
        },
        {
          fieldName: 'description',
          text: 'Description',
        },
        {
          fieldName: 'targetClass',
          text: 'Target Class',
        },
        {
          fieldName: 'required',
          text: 'Required',
          align: 'right',
        },
        {
          fieldName: 'readOnly',
          text: 'Read Only',
          align: 'right',
        },
      ],
      attributeRows: [],
      relationshipRows: [],
      className: {
        label: 'Name',
        required: true,
        value: '',
      },
      dataSourceOptions: [],
      dataSource: [],
    };
  },
  async mounted() {
    this.updateTitle();
    this.load();
    //TODO: called isAuthenticate twice
    this.isLoadingAuth = true;
    await this.isAuthenticated();
    this.isLoadingAuth = false;
    window.addEventListener('beforeunload', this.onLeavePage);
  },
  destroyed() {
    window.removeEventListener('beforeunload', this.onLeavePage);
  },
  methods: {
    updateTitle() {
      if (this.activeClass) {
        document.title = `${this.decamelize(this.activeClass)} ${
          this.isEditing ? ' | Edit Class ' : ''
        }| ${process.env.VUE_APP_TITLE}`;
      } else {
        document.title = this.isCreateClass
          ? `Create A New Class | ${process.env.VUE_APP_TITLE}`
          : `Schema Management | ${process.env.VUE_APP_TITLE}`;
      }
    },
    async load() {
      this.isLoading = true;
      await this.$store.dispatch('getAllClasses');
      if (this.isEditing) {
        await this.loadDataSourceOptions();
      }
      this.isLoading = false;
      this.loadClassObj();
    },
    async loadDataSourceOptions() {
      if (this.dataSourceOptions.length > 0) return;
      const response_body = await Request.get('/query', {
        query: "g.V().hasLabel('database')",
      });
      if (response_body !== undefined) {
        let dataSourceOptions = [];
        response_body.forEach(element => {
          dataSourceOptions.push({
            text: element['description'],
            value: element['description'],
          });
        });
        this.dataSourceOptions = dataSourceOptions;
      }
    },
    loadClassObj() {
      this.classMap = this.$store.state.schema.classMap;
      this.markClean();
      if (!this.activeClass) {
        this.clearClassObj();
        return;
      }
      if (!this.$store.state.schema.classMap.hasOwnProperty(this.activeClass)) {
        this.isClassExist = false;
        this.clearClassObj();
        return;
      }
      this.isClassExist = true;
      this.classObj = this.deepCopy(
        this.$store.state.schema.classMap[this.activeClass],
      );
      this.buildAttributes();
      this.buildRelationships();
    },
    clearClassObj() {
      this.classObj = {};
      this.attributeRows = [];
      this.relationshipRows = [];
    },
    buildAttributes() {
      this.classObj['attributes'].forEach(element => {
        element['id'] = element['name'];
      });
      this.attributeRows = this.classObj['attributes'];
    },
    buildRelationships() {
      this.classObj['relationships'].forEach(element => {
        element['id'] = `${element['name']}&${element['targetClass']}`;
      });
      this.relationshipRows = this.classObj['relationships'];
    },
    async submit() {
      if (!this.validate()) return;
      const actionName = this.isCreateClass ? 'addClass' : 'updateClass';
      let className = this.className.value;
      if (this.className.value !== this.className.value.toUpperCase()) {
        className = this.camelize(this.className.value);
      }
      const classObject = {
        name: className,
        dataSource: this.dataSource,
        attributes: this.$refs.Attributes.submit(),
        relationships: this.$refs.Relationships.submit(),
      };
      this.isSubmiting = true;
      const this_ = this;
      await this.$store.dispatch(actionName, {
        classObject: classObject,
        onSuccessFunc: async function() {
          this_.stopEditing({ className: className });
          this_.loadClassObj();
        },
      });
      this.isSubmiting = false;
    },
    validate() {
      // Validate class name
      this.className.value = this.className.value.trim();
      if (this.className.value === '') {
        this.$set(this.className, 'error', true);
        this.$set(this.className, 'errorText', [this.REQUIRED_MSG]);
        this.notifyError('Class name is required!');
        return false;
      }
      this.$set(this.className, 'error', false);
      if (this.isCreateClass) {
        const isClassExist = this.$store.state.schema.navList.includes(
          this.className.value,
        );
        if (isClassExist) {
          this.notifyError(`The class ${this.className.value} exists!`);
          return false;
        }
      }
      if (this.dataSource.length < 1) {
        this.notifyError('Requires at least one Datasource!');
        return false;
      }
      // Validate attribute and relationship
      let pass = this.$refs.Attributes.validate();
      pass = this.$refs.Relationships.validate() && pass;
      return pass;
    },
    markDirty() {
      this.isDirty = true;
    },
    markClean() {
      this.isDirty = false;
    },
    async deleteClass() {
      this.toggleDelete = false;
      this.isDeleting = true;
      const this_ = this;
      const isSchemaActive = await this.isSchemaActive(
        this_.className.value.trim(),
      );
      if (!isSchemaActive) {
        await this.$store.dispatch('deleteClass', {
          className: this_.className.value.trim(),
          onSuccessFunc: async function() {
            await this_.$store.dispatch('getAllClasses', true);
            this_.$router.go(-1);
          },
        });
      } else {
        this.notifyError('Unable to delete. Schema is in use!');
      }

      this.isDeleting = false;
    },
    async isSchemaActive(schema) {
      const count = await Request.get('/query', {
        query: `g.V().hasLabel('${schema}').count()`,
      });
      if (count[0] > 0) return true;
      return false;
    },
    cancelEditing() {
      if (!this.confirmStayInDirtyForm(this.isDirty)) {
        this.stopEditing(this.$route.params);
      }
    },
    stopEditing(params) {
      this.componentKey += 1;
      this.routeToPage('ClassView', {}, params);
    },
    async startEditing() {
      this.componentKey += 1;
      this.routeToPage('ClassEdit', {}, this.$route.params);
      this.isLoading = true;
      await this.loadDataSourceOptions();
      this.isLoading = false;
    },
    onLeavePage(e) {
      this.beforeWindowUnload(e, this.isDirty);
    },
  },
  computed: {
    hasAccess() {
      return !this.isEditing || this.userGroup === 'admin';
    },
    isEditing() {
      return (
        this.$route.name === 'ClassCreate' || this.$route.name === 'ClassEdit'
      );
    },
    isCreateClass() {
      return this.$route.name === 'ClassCreate';
    },
    showSummary() {
      return (
        (this.$route.params.className === undefined ||
          this.$route.params.className === null) &&
        !this.isCreateClass
      );
    },
    activeClass() {
      return this.$route.params.className;
    },
  },
  watch: {
    async activeClass() {
      this.updateTitle();
      await this.loadClassObj();
    },
    isEditing() {
      this.updateTitle();
    },
    classObj(newValue) {
      this.className.value = newValue[this.PPT_NAME] || '';
      this.dataSource =
        newValue[this.PPT_DATA_SOURCE] === undefined
          ? []
          : newValue[this.PPT_DATA_SOURCE];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/global.scss';
.label-spacing {
  margin: #{$mds-space-half-x} 0 #{$mds-space-half-x} 0;
}
</style>
