import Request from '@/js/request.js';

export default {
  state: {
    userId: null,
    userGroup: null,
  },
  mutations: {
    setUserGroup(state, response_body) {
      if (response_body === undefined) {
        state.userGroup = null;
      } else {
        state.userGroup = response_body['role'];
      }
    },
  },
  actions: {
    async getUserGroup({ commit, state }, userId) {
      if (userId === state.userId && state.userGroup !== null) return;
      state.userId = userId;
      const response_body = await Request.get('user_group', { userId: userId });
      commit('setUserGroup', response_body);
    },
    async setUserAsAdmin({ state }, userId) {
      state.userId = userId;
      state.userGroup = 'admin';
    },
  },
};
