<template>
  <mds-page-shell
    :logo="require('@/assets/Mstar-logo-50px-taxonomy.svg')"
    :logo-responsive="require('@/assets/Mstar-logo-50px.svg')"
    logo-href="/"
    logo-alt="Home"
    hidden-menu
    sticky
  >
    <template v-slot:mds-page-shell-masthead-left>
      <TermSearchField v-if="showSearchField" />
    </template>

    <template v-slot:mds-page-shell-masthead-right>
      <div>
        <div v-if="!authenticated">
          <div
            class="mds-masthead--hide-wide mds-page-shell__menu-button--responsive"
          >
            <mds-button
              variation="icon-only"
              icon="person"
              type="button"
              @click="login"
            ></mds-button>
          </div>
          <div class="mds-masthead--show-wide">
            <mds-button
              variation="flat"
              icon="person"
              flat-button-icon-size="medium"
              text="Sign In"
              type="button"
              @click="login"
            ></mds-button>
          </div>
        </div>
        <div v-else>
          <div class="mds-masthead--hide-wide">
            <mds-button
              variation="icon-only"
              icon="person"
              type="button"
              @click="userPopover = !userPopover"
            ></mds-button>
          </div>
          <div class="mds-masthead--show-wide mds-page-shell__menu-button">
            <mds-button
              id="popover-trigger"
              variation="flat"
              icon="person"
              icon-right="caret-down"
              flat-button-icon-size="medium"
              :text="user.given_name"
              type="button"
              @click="userPopover = !userPopover"
            ></mds-button>
            <mds-popover
              triggered-by="popover-trigger"
              v-model="userPopover"
              position="bottom-left"
              width="200px"
              title="My Account"
            >
              <mds-button
                variation="secondary"
                text="Sign Out"
                type="button"
                :autofocus="false"
                @click="logout"
              ></mds-button>
            </mds-popover>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:mds-page-shell-hidden-nav>
      <AppHiddenMenu />
      <div class="mds-masthead--hide-wide spacing-btw-hidden-vertical">
        <slot name="mds-page-shell-vertical-nav"></slot>
      </div>
    </template>

    <template v-slot:mds-page-shell-vertical-nav>
      <slot name="mds-page-shell-vertical-nav"></slot>
    </template>

    <div class="mds-page-shell__content-spacing">
      <slot></slot>
    </div>
  </mds-page-shell>
</template>

<script>
import Auth from '@/js/auth.js';
import AppHiddenMenu from './AppHiddenMenu.vue';
import TermSearchField from '@/components/TermManagement/TermSearchField.vue';
import { MdsButton } from '@mds/button';
import MdsPageShell from '@mds/page-shell';
import MdsPopover from '@mds/popover';

export default {
  name: 'AppMasthead',
  components: {
    AppHiddenMenu,
    TermSearchField,
    MdsButton,
    MdsPageShell,
    MdsPopover,
  },
  props: {
    showSearchField: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [Auth],
  async created() {
    await this.isAuthenticated();
  },
  data: function() {
    return {
      userPopover: false,
    };
  },
  methods: {
    userButtonClicked() {
      this.userPopover = !this.userPopover;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@mds/constants';
@import '@mds/page-shell/src/page-shell_constants';
.spacing-btw-hidden-vertical {
  margin-top: $mds-space-2-x;
}
.mds-page-shell__content-spacing {
  padding: $mds-space-2-x $mds-space-2-x;
}
.mds-masthead--hide-wide {
  @media screen and (min-width: $mds-page-shell-hide-wide-breakpoint) {
    display: none;
  }
}
.mds-masthead--show-wide {
  display: none;
  @media screen and (min-width: $mds-page-shell-hide-wide-breakpoint) {
    display: inherit;
  }
}
</style>
