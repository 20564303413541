<template>
  <app-masthead>
    <template v-slot:mds-page-shell-vertical-nav>
      <WorkingDashboardNav />
    </template>
    <mds-loader v-if="isLoading"></mds-loader>
    <div v-else-if="!isLoading" class="graphs">
      <div class="graph-row">
        <div class="graph">
          <WorkingDashboardDonut :title="articlesTitle" :data="articleValues" />
        </div>
        <div class="graph">
          <WorkingDashboardDonut :title="videosTitle" :data="videoValues" />
        </div>
      </div>
    </div>
  </app-masthead>
</template>

<script>
import AppMasthead from '@/components/elements/AppMasthead.vue';
import MdsLoader from '@mds/loader';
import WorkingDashboardNav from './WorkingDashboardNav.vue';
import WorkingDashboardDonut from './WorkingDashboardDonut.vue';
import Request from '@/js/request.js';

export default {
  name: 'WorkingDashboardStatus',
  components: {
    AppMasthead,
    MdsLoader,
    WorkingDashboardNav,
    WorkingDashboardDonut,
  },
  data() {
    return {
      results: [],
      articleValues: [],
      articlesTitle: 'Articles',
      isLoading: false,
      videoValues: [],
      videosTitle: 'Videos',
    };
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      const response_body = await Request.get('/dashboard');
      this.results = response_body;
      const totalArticles =
        this.results.article_mapped + this.results.article_unmapped;
      const totalVideos =
        this.results.video_mapped + this.results.video_unmapped;
      this.articleValues.push(
        {
          name: 'Mapped',
          value: this.results.article_mapped,
          percentage: `${Math.round(
            (this.results.article_mapped / totalArticles) * 100,
          )}%`,
        },
        {
          name: 'Unmapped',
          value: this.results.article_unmapped,
          percentage: `${Math.round(
            (this.results.article_unmapped / totalArticles) * 100,
          )}%`,
        },
      );
      this.videoValues.push(
        {
          name: 'Mapped',
          value: this.results.video_mapped,
          percentage: `${Math.round(
            (this.results.video_mapped / totalVideos) * 100,
          )}%`,
        },
        {
          name: 'Unmapped',
          value: this.results.video_unmapped,
          percentage: `${Math.round(
            (this.results.video_unmapped / totalVideos) * 100,
          )}%`,
        },
      );
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/global.scss';
@import '@mds/constants';

.graphs {
  display: table;
  margin-top: -#{$mds-space-2-x};
  width: 90%;
}
.graphs .graph-row {
  display: table-row;
}
.graphs .graph-row .graph {
  display: table-cell;
  padding: 0px 40px 0px 40px;
  width: 33%;
}
</style>
