<template>
  <mds-label :required="data.required" :text="data.label" class="inline-text">
    <AppIconTooltip
      v-if="data.tooltip"
      variation="icon-only"
      icon="info-circle"
      size="small"
      position="right-center"
      :tooltip="data.tooltip"
      class="margin-left-4"
    />
    <mds-textarea
      hidden-label
      v-bind="data"
      v-model="data.value"
      @change="inputChanged"
      @keydown.enter.prevent=""
    ></mds-textarea>
  </mds-label>
</template>

<script>
import MdsTextarea from '@mds/textarea';
import MdsLabel from '@mds/label';
import AppIconTooltip from '@/components/elements/AppIconTooltip.vue';

export default {
  name: 'AppTextArea',
  components: {
    MdsTextarea,
    MdsLabel,
    AppIconTooltip,
  },
  props: {
    data: { type: Object, default: null },
  },
  methods: {
    inputChanged() {
      this.$emit('change', this.data.value);
    },
  },
};
</script>

<style scoped>
.margin-left-4 {
  margin-left: 4px;
}
</style>
