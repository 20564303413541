<template>
  <nav>
    <mds-section title="All Classes">
      <template v-slot:mds-section-actions>
        <AppIconTooltip
          class="mds-section-actions-spacing"
          icon="plus"
          tooltip="create a class"
          @click="routeToPage('ClassCreate', {}, {})"
        />
      </template>
      <mds-list-group>
        <mds-loader v-if="isLoading"></mds-loader>
      </mds-list-group>
      <mds-list-group variation="navigation">
        <mds-list-group-item
          v-for="item in navList"
          :key="item"
          :active="item === activeItem"
          :text="decamelize(item)"
          @mds-list-item-clicked="
            routeToPage('ClassView', {}, { className: item })
          "
        >
        </mds-list-group-item>
      </mds-list-group>
    </mds-section>
  </nav>
</template>

<script>
import AppIconTooltip from '@/components/elements/AppIconTooltip.vue';
import { MdsListGroup, MdsListGroupItem } from '@mds/list-group';
import MdsLoader from '@mds/loader';
import MdsSection from '@mds/section';
import Utils from '@/js/utils.js';

export default {
  name: 'SchemaNav',
  components: {
    AppIconTooltip,
    MdsListGroup,
    MdsListGroupItem,
    MdsLoader,
    MdsSection,
  },
  props: {
    isLoading: Boolean,
  },
  mixins: [Utils],
  computed: {
    activeItem() {
      return this.$route.query.className;
    },
    navList() {
      return this.$store.state.schema.navList;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/global.scss';
.mds-section-actions-spacing {
  margin: 0 0 #{-$mds-space-1-x} 0;
}
</style>
